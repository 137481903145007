import { Chip } from "@mui/material";
function ShipmentStatusChip(props) {
  const { status, color, variant, size, onClick, sx } = props;

  return (
    <Chip
      label={status}
      variant={variant}
      color={color ?? "info"}
      size={size}
      onClick={onClick}
      sx={sx}
    />
  );
}

export default ShipmentStatusChip;
