import { useEffect, useState } from "react";
import ShipmentService from "../features/shipments/shipmentService";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import {
  addShipmentTracking,
  updateShipmentTracking,
} from "../features/shipments/shipmentSlice";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  TableContainer,
  TextField,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const initialFormDataState = {
  id: null,
  shipment_id: null,
  type: "",
  number: "",
};

function TrackingTable(props) {
  const { shipment, isEditable } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [newRowVisible, setNewRowVisible] = useState(false);
  const [editableRowId, setEditableRowId] = useState(null);
  const [formData, setFormData] = useState(initialFormDataState);
  const [duplicateConfirmationDialog, setDuplicateConfirmationDialog] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (editableRowId) {
      setFormData(
        shipment.ShipmentTrackings.filter((e) => e.id === editableRowId)[0]
      );
    } else {
      setFormData(initialFormDataState);
    }
  }, [editableRowId, shipment.ShipmentTrackings]);

  const handleNewRowClick = () => {
    setNewRowVisible(true);
    setEditableRowId(null);
  };

  const handleFormDataChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDuplicateConfirmationDialogClose = () => {
    setDuplicateConfirmationDialog(false);
  };

  const handleViewDataTracking = (e) => {
    const queryParams = { q: formData.number.trim() };
    const qString = queryString.stringify(queryParams);
    navigate(`/?${qString}`);
    setDuplicateConfirmationDialog(false);
  };

  const handleSubmitFormData = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const trackingNo = await ShipmentService.checkShipmentTracking(
      formData.number.trim()
    );

    if (trackingNo.exists) {
      setDuplicateConfirmationDialog(true);
    } else {
      await submitFormData();
    }
    setIsLoading(false);
  };

  const submitFormData = async () => {
    if (formData.id) {
      dispatch(
        updateShipmentTracking({ ...formData, number: formData.number.trim() })
      );
    } else {
      dispatch(
        addShipmentTracking({
          shipment_id: shipment.id,
          type: formData.type,
          number: formData.number.trim(),
        })
      );
      setNewRowVisible(false);
    }
    setEditableRowId(null);
    setDuplicateConfirmationDialog(false);
    setIsLoading(false);
  };

  return (
    <TableContainer>
      <Table aria-label="tracking-table" size="small" sx={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell>Tracking</TableCell>
            <TableCell>Number</TableCell>
            {isEditable && (
              <TableCell align="right">
                {!newRowVisible && (
                  <IconButton
                    aria-label="Add Tracking Number"
                    onClick={() => handleNewRowClick()}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {shipment.ShipmentTrackings &&
            shipment.ShipmentTrackings.map((row, i) => {
              return (
                <TableRow
                  key={i}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ overflowWrap: "anywhere" }}
                  >
                    {row.id === editableRowId ? (
                      <TextField
                        size="small"
                        fullWidth
                        autoFocus
                        variant="standard"
                        name="type"
                        value={formData.type}
                        onChange={handleFormDataChange}
                      />
                    ) : (
                      row.type
                    )}
                  </TableCell>
                  <TableCell sx={{ overflowWrap: "anywhere" }}>
                    {row.id === editableRowId ? (
                      <form onSubmit={handleSubmitFormData}>
                        <TextField
                          size="small"
                          fullWidth
                          variant="standard"
                          name="number"
                          value={formData.number}
                          onChange={handleFormDataChange}
                        />
                      </form>
                    ) : (
                      row.number
                    )}
                  </TableCell>
                  {isEditable && (
                    <TableCell align="right">
                      {row.id === editableRowId ? (
                        <Stack direction="row" justifyContent="flex-end">
                          <IconButton
                            aria-label="submit"
                            disabled={isLoading}
                            onClick={handleSubmitFormData}
                          >
                            <CheckIcon />
                          </IconButton>
                          <IconButton
                            aria-label="cancel"
                            disabled={isLoading}
                            onClick={() => setEditableRowId(null)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Stack>
                      ) : (
                        <IconButton
                          aria-label="Edit"
                          disabled={isLoading}
                          onClick={() => setEditableRowId(row.id)}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          {newRowVisible && (
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                sx={{ overflowWrap: "anywhere" }}
              >
                <TextField
                  size="small"
                  fullWidth
                  autoFocus
                  variant="standard"
                  name="type"
                  value={formData.type}
                  onChange={handleFormDataChange}
                />
              </TableCell>
              <TableCell sx={{ overflowWrap: "anywhere" }}>
                <form onSubmit={handleSubmitFormData}>
                  <TextField
                    size="small"
                    fullWidth
                    variant="standard"
                    name="number"
                    value={formData.number}
                    onChange={handleFormDataChange}
                  />
                </form>
              </TableCell>
              <TableCell align="right">
                <Stack direction="row" justifyContent="flex-end">
                  <IconButton
                    aria-label="submit"
                    disabled={isLoading}
                    onClick={handleSubmitFormData}
                  >
                    <CheckIcon />
                  </IconButton>
                  <IconButton
                    aria-label="cancel"
                    disabled={isLoading}
                    onClick={() => setNewRowVisible(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Dialog
        open={duplicateConfirmationDialog}
        onClose={handleDuplicateConfirmationDialogClose}
        aria-labelledby="tracking-number-duplicate-confirmation"
        aria-describedby="tracking-number-duplicate-confirmation"
      >
        <DialogTitle>{"Duplicate Tracking Number"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Nomor tracking: {formData.type} - {formData.number} sudah pernah
            di-input sebelumnya.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleViewDataTracking}>
            Lihat Data
          </Button>
          <Button
            color="inherit"
            onClick={handleDuplicateConfirmationDialogClose}
          >
            Batalkan
          </Button>
          <Button onClick={submitFormData} autoFocus>
            Lanjutkan
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}

export default TrackingTable;
