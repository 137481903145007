import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import notificationService from "./notificationService";

const initialState = {
  notifications: [],
  shipmentLogs: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get notifications
export const getNotifications = createAsyncThunk(
  "notifications/getAll",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await notificationService.getNotifications(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get shipment logs
export const getShipmentLogs = createAsyncThunk(
  "notifications/getPerShipment",
  async (shipment_id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await notificationService.getShipmentLogs(shipment_id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update notification
export const readNotification = createAsyncThunk(
  "notifications/read",
  async (notificationData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await notificationService.readNotification(
        notificationData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Read all notification
export const readAllNotifications = createAsyncThunk(
  "notifications/readAll",
  async (notificationData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await notificationService.readAllNotifications(
        notificationData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.notifications = action.payload;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getShipmentLogs.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(getShipmentLogs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
        state.notificationsPerShipment = action.payload;
      })
      .addCase(getShipmentLogs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(readNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(readNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "";
        state.isError = false;

        if (state.notifications.length > 0) {
          const objIndex = state.notifications.findIndex(
            (obj) => obj.id === action.payload.id
          );
          let notifications = [...state.notifications];
          notifications[objIndex].read = action.payload.read;
          notifications[objIndex].readBy = action.payload.readBy;
          notifications[objIndex].readAt = action.payload.readAt;
          state.notifications = notifications;
        }
      })
      .addCase(readNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(readAllNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(readAllNotifications.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "";
        state.isError = false;

        if (state.notifications.length > 0) {
          let notifications = [...state.notifications];
          notifications.forEach((n, i) => {
            notifications[i].read = action.payload.read;
            notifications[i].readBy = action.payload.readBy;
            notifications[i].readAt = action.payload.readAt;
          });
          state.notifications = notifications;
        }
      })
      .addCase(readAllNotifications.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = notificationSlice.actions;
export default notificationSlice.reducer;
