import { useEffect, useState } from "react";
import {
  Typography,
  IconButton,
  Stack,
  TextField,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { NumericFormat } from "react-number-format";

const currencies = [
  {
    value: "IDR",
    label: "IDR (Rp.)",
  },
  {
    value: "USD",
    label: "USD ($)",
  },
  {
    value: "SGD",
    label: "SGD ($)",
  },
  {
    value: "EUR",
    label: "EUR (€)",
  },
  {
    value: "GBP",
    label: "GBP (£)",
  },
  {
    value: "JPY",
    label: "JPY (¥)",
  },
];

function EditableNumericTextLabel(props) {
  const {
    name,
    currencyName,
    currencyValue,
    title,
    titleVariant,
    prefix,
    suffix,
    value,
    valueVariant,
    useCurrency,
    forceDecimal,
    valueSx,
    fullWidth,
    multiline,
    onSubmit,
    allowEdit,
  } = props;
  const [isHovering, setIsHovering] = useState(false);
  const [tempValue, setTempValue] = useState(value ?? "");
  const [isEditable, setIsEditable] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("IDR");
  useEffect(() => {
    setTempValue(value ?? "");
    setSelectedCurrency(currencyValue ?? "");
  }, [value, currencyValue]);

  const handleCancelEdit = () => {
    setIsEditable(false);
    setTempValue(value);
    setSelectedCurrency(currencyValue ?? "");
    setIsHovering(false);
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleSubmmit = () => {
    if (useCurrency) {
      onSubmit({ [name]: tempValue, [currencyName]: selectedCurrency });
    } else {
      onSubmit({ [name]: tempValue });
    }
    setIsEditable(false);
    setIsHovering(false);
  };
  return (
    <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      <Stack direction="row">
        <div onClick={() => !isEditable && setIsEditable(true)}>
          <Typography variant={titleVariant}>{title}</Typography>
        </div>

        {allowEdit && (
          <IconButton
            aria-label="edit"
            size="small"
            sx={{
              my: -1.5,
              visibility: isHovering && !isEditable ? "visible" : "hidden",
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={() => setIsEditable(true)}
          >
            <EditIcon />
          </IconButton>
        )}
      </Stack>
      {!isEditable ? (
        <NumericFormat
          displayType="text"
          decimalSeparator=","
          valueIsNumericString={true}
          thousandSeparator="."
          value={value}
          prefix={prefix}
          suffix={suffix}
          decimalScale={2}
          fixedDecimalScale={forceDecimal}
          renderText={(value) => (
            <Typography
              variant={valueVariant}
              sx={valueSx}
              className="display-linebreak"
            >
              {currencyValue} {value}
            </Typography>
          )}
        />
      ) : (
        <>
          <Stack direction="row" spacing={1}>
            {useCurrency && (
              <TextField
                select
                size="small"
                defaultValue="IDR"
                value={selectedCurrency}
                onChange={(e) => setSelectedCurrency(e.target.value)}
                sx={{ width: 120 }}
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <NumericFormat
              customInput={TextField}
              size="small"
              multiline={multiline}
              fullWidth={fullWidth}
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
              fixedDecimalScale={forceDecimal}
              valueIsNumericString={true}
              name={name}
              value={value}
              onValueChange={(values) => {
                setTempValue(values.value);
              }}
              InputProps={{
                startAdornment: prefix && !useCurrency && (
                  <InputAdornment position="start">{prefix}</InputAdornment>
                ),
                endAdornment: suffix && (
                  <InputAdornment position="start">{suffix}</InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack direction="row">
            <IconButton size="small" onClick={handleSubmmit}>
              <CheckIcon />
            </IconButton>
            <IconButton size="small" onClick={() => handleCancelEdit()}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </>
      )}
    </div>
  );
}

export default EditableNumericTextLabel;
