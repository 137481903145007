import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import shipmentService from "./shipmentService";

const initialState = {
  shipments: [],
  shipment: {
    shipmentNo: "",
    from: "",
    to: "",
  },
  shipmentLogs: [],
  folders: [],
  addresses: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

const resetShipmentTotal = (shipment, newValues) => {
  shipment.totalItemQty = newValues.totalItemQty ?? "0";
  shipment.totalItemWeight = newValues.totalItemWeight ?? "0";
  shipment.totalItemVolume = newValues.totalItemVolume ?? "0";
  shipment.totalItemVolumeWeight = newValues.totalItemVolumeWeight ?? "0";
  shipment.totalChargeable = newValues.totalChargeable ?? "0";
  return shipment;
};

// Get shipment Folders
export const getShipmentFolders = createAsyncThunk(
  "shipments/getFolders",
  async (query, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.getShipmentFolders(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get shipment Folders
export const updateShipmentFolder = createAsyncThunk(
  "shipments/updateFolder",
  async (folderData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.updateShipmentFolder(folderData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create temp shipment folder
// It's not actually creating a folder data in db.
// Just adding into the state
export const createTempShipmentFolder = createAsyncThunk(
  "shipments/createTempFolder",
  async (name, _) => {
    return {
      folder: name.toUpperCase(),
      createdAt: new Date().toISOString(),
    };
  }
);

// Create new shipment
export const createShipment = createAsyncThunk(
  "shipments/create",
  async (shipmentData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.createShipment(shipmentData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get shipments
export const getShipments = createAsyncThunk(
  "shipments/getAll",
  async (query, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.getShipments(query, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get shipment
export const getShipment = createAsyncThunk(
  "shipments/getSingle",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.getShipment(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get shipment Public
export const getShipmentPublic = createAsyncThunk(
  "shipments/getSinglePublic`",
  async (id, thunkAPI) => {
    try {
      return await shipmentService.getShipmentPublic(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update shipment
export const updateShipment = createAsyncThunk(
  "shipments/update",
  async (shipmentData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.updateShipment(shipmentData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete shipment
export const deleteShipment = createAsyncThunk(
  "shipments/delete",
  async (shipment_id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.deleteShipment(shipment_id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update shipment isclosed
export const updateShipmentIsClosed = createAsyncThunk(
  "shipments/updateIsClosed",
  async (shipmentData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.updateShipmentIsClosed(shipmentData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update shipment status = Create / add shipmentStatus
export const updateShipmentStatus = createAsyncThunk(
  "shipments/updateStatus",
  async (shipmentStatusData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.updateShipmentStatus(
        shipmentStatusData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Edit shipment status
export const editShipmentStatus = createAsyncThunk(
  "shipments/editStatus",
  async (shipmentStatusData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.editShipmentStatus(
        shipmentStatusData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create shipment status draft (Approve / Decline)
export const createShipmentStatusDraft = createAsyncThunk(
  "shipments/createStatusDraft",
  async (shipmentStatusData, thunkAPI) => {
    try {
      return await shipmentService.createShipmentStatusDraft(
        shipmentStatusData
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete shipment status draft (Approve / Decline)
export const deleteShipmentStatusDraft = createAsyncThunk(
  "shipments/deleteStatusDraft",
  async (shipmentStatusData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.deleteShipmentStatusDraft(
        shipmentStatusData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add Shipment Image
export const addShipmentImage = createAsyncThunk(
  "shipments/addShipmentImage",
  async (shipmentImageData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.addShipmentImage(shipmentImageData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Shipment Image
export const deleteShipmentImage = createAsyncThunk(
  "shipments/deleteShipmentImage",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.deleteShipmentImage(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add Shipment Item
export const addShipmentItem = createAsyncThunk(
  "shipments/addShipmentItem",
  async (shipmentItemData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.addShipmentItem(shipmentItemData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Shipment Item
export const updateShipmentItem = createAsyncThunk(
  "shipments/updateShipmentItem",
  async (shipmentItemData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.updateShipmentItem(shipmentItemData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Shipment Item
export const deleteShipmentItem = createAsyncThunk(
  "shipments/deleteShipmentItem",
  async (shipmentItemData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.deleteShipmentItem(shipmentItemData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add Shipment Package
export const addShipmentPackage = createAsyncThunk(
  "shipments/addShipmentPackage",
  async (shipmentPackageData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.addShipmentPackage(
        shipmentPackageData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Bulk Add Shipment Package
export const bulkAddShipmentPackage = createAsyncThunk(
  "shipments/bulkAddShipmentPackage",
  async (shipmentPackageData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.bulkAddShipmentPackage(
        shipmentPackageData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Shipment Package
export const updateShipmentPackage = createAsyncThunk(
  "shipments/updateShipmentPackage",
  async (shipmentPackageData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.updateShipmentPackage(
        shipmentPackageData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Shipment Package
export const deleteShipmentPackage = createAsyncThunk(
  "shipments/deleteShipmentPackage",
  async (shipmentPackageData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.deleteShipmentPackage(
        shipmentPackageData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Bulk Delete Shipment Package
export const bulkDeleteShipmentPackage = createAsyncThunk(
  "shipments/bulkDeleteShipmentPackage",
  async (shipmentPackageData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.bulkDeleteShipmentPackage(
        shipmentPackageData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add Shipment Tracking
export const addShipmentTracking = createAsyncThunk(
  "shipments/addShipmentTracking",
  async (shipmentTrackingData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.addShipmentTracking(
        shipmentTrackingData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Shipment Tracking
export const updateShipmentTracking = createAsyncThunk(
  "shipments/updateShipmentTracking",
  async (shipmentTrackingData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.updateShipmentTracking(
        shipmentTrackingData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Shipment Tracking
export const getShipmentLogs = createAsyncThunk(
  "shipments/logs",
  async (shipment_id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.getShipmentLogs(shipment_id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get shipment address
export const getShipmentAddress = createAsyncThunk(
  "shipments/getAddress",
  async (query, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shipmentService.getShipmentAddress(query, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const shipmentSlice = createSlice({
  name: "shipment",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShipmentFolders.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(getShipmentFolders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.folders = action.payload;
      })
      .addCase(getShipmentFolders.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateShipmentFolder.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(updateShipmentFolder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = null;
        let folders = state.folders.map((folder) => {
          return {
            ...folder,
            folder: folder.folder.replace(
              action.payload.oldName,
              `${action.payload.newName}/`
            ),
          };
        });
        state.folders = folders;
      })
      .addCase(updateShipmentFolder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(createShipment.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(createShipment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.shipment = action.payload;
      })
      .addCase(createShipment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getShipments.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
        state.shipments = initialState.shipments;
      })
      .addCase(getShipments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.shipments = action.payload;
      })
      .addCase(getShipments.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getShipment.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
        state.shipments = initialState.shipments;
      })
      .addCase(getShipment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.shipment = action.payload;
      })
      .addCase(getShipment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getShipmentPublic.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
        state.shipments = initialState.shipments;
      })
      .addCase(getShipmentPublic.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.shipment = action.payload;
      })
      .addCase(getShipmentPublic.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateShipment.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(updateShipment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (state.shipments.length > 0) {
          const shipmentIndex = state.shipments.findIndex(
            (obj) => obj.id === action.payload.id
          );
          state.shipments[shipmentIndex] = {
            ...state.shipments[shipmentIndex],
            ...action.payload,
          };
        }
        if (state.shipment.id) {
          state.shipment = { ...state.shipment, ...action.payload };
        }
      })
      .addCase(updateShipment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateShipmentIsClosed.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
        state.message = "";
      })
      .addCase(updateShipmentIsClosed.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
        if (state.shipments.length > 0) {
          const shipmentIndex = state.shipments.findIndex(
            (obj) => obj.id === action.payload.id
          );
          state.shipments[shipmentIndex] = {
            ...state.shipments[shipmentIndex],
            ...action.payload,
          };
          state.shipments[shipmentIndex].ShipmentStatuses.push(
            action.payload.shipmentStatus
          );
        }
        if (state.shipment.id) {
          state.shipment = { ...state.shipment, ...action.payload };
          state.shipment.ShipmentStatuses.push(action.payload.shipmentStatus);
        }
      })
      .addCase(updateShipmentIsClosed.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteShipment.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(deleteShipment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
        if (state.shipments.length > 0) {
          const shipmentIndex = state.shipments.findIndex(
            (obj) => obj.id === action.payload.id
          );
          state.shipments = state.shipments.filter(
            (s) => s.id === action.payload.id
          );
        }
        if (state.shipment.id) {
          state.shipment = {};
        }
      })
      .addCase(deleteShipment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateShipmentStatus.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(updateShipmentStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (state.shipments.length > 0) {
          const objIndex = state.shipments.findIndex(
            (obj) => obj.id === action.payload.shipment_id
          );
          let shipments = [...state.shipments];
          shipments[objIndex].ShipmentStatuses.push(action.payload);
          state.shipments = shipments;
        }
        if (state.shipment.id) {
          let shipment = { ...state.shipment };
          shipment.ShipmentStatuses.push(action.payload);
          state.shipment = shipment;
        }
      })
      .addCase(updateShipmentStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(editShipmentStatus.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(editShipmentStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (state.shipments.length > 0) {
          const shipmentIndex = state.shipments.findIndex(
            (obj) => obj.id === action.payload.shipment_id
          );
          let shipments = [...state.shipments];

          const shipmentItemIndex = shipments[
            shipmentIndex
          ].ShipmentStatuses.findIndex((obj) => obj.id === action.payload.id);

          shipments[shipmentIndex].ShipmentStatuses[shipmentItemIndex] =
            action.payload;
          state.shipments = shipments;
        }

        if (state.shipment.id) {
          let shipment = { ...state.shipment };
          const shipmentItemIndex = state.shipment.ShipmentStatuses.findIndex(
            (obj) => obj.id === action.payload.id
          );
          shipment.ShipmentStatuses[shipmentItemIndex] = action.payload;
          state.shipment = shipment;
        }
      })
      .addCase(editShipmentStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createShipmentStatusDraft.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(createShipmentStatusDraft.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (state.shipments.length > 0) {
          const objIndex = state.shipments.findIndex(
            (obj) => obj.id === action.payload.shipment_id
          );
          let shipments = [...state.shipments];
          if (shipments[objIndex].ShipmentStatusDrafts) {
            shipments[objIndex].ShipmentStatusDrafts.push(action.payload);
          } else {
            shipments[objIndex].ShipmentStatusDrafts = [action.payload];
          }
          state.shipments = shipments;
        }
        if (state.shipment.id) {
          let shipment = { ...state.shipment };
          if (shipment.ShipmentStatusDrafts) {
            shipment.ShipmentStatusDrafts.push(action.payload);
          } else {
            shipment.ShipmentStatusDrafts = [action.payload];
          }
          state.shipment = shipment;
        }
      })
      .addCase(createShipmentStatusDraft.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteShipmentStatusDraft.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(deleteShipmentStatusDraft.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (state.shipments.length > 0) {
          const shipmentIndex = state.shipments.findIndex(
            (obj) => obj.id === action.payload.shipment_id
          );
          let shipments = [...state.shipments];

          const shipmentStatusDraftIndex = shipments[
            shipmentIndex
          ].ShipmentStatusDrafts.findIndex(
            (obj) => obj.id === action.payload.id
          );

          shipments[shipmentIndex].ShipmentStatusDrafts.splice(
            shipmentStatusDraftIndex,
            1
          );
          state.shipments = shipments;
        }

        if (state.shipment.id) {
          let shipment = { ...state.shipment };
          const shipmentStatusDraftIndex =
            state.shipment.ShipmentStatusDrafts.findIndex(
              (obj) => obj.id === action.payload.id
            );
          shipment.ShipmentStatusDrafts.splice(shipmentStatusDraftIndex, 1);

          if (action.payload.approve)
            shipment.ShipmentStatuses.push(action.payload);

          state.shipment = shipment;
        }
      })
      .addCase(deleteShipmentStatusDraft.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addShipmentImage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(addShipmentImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        if (state.shipments.length > 0) {
          const objIndex = state.shipments.findIndex(
            (obj) => obj.id === action.payload.shipment_id
          );
          let shipments = [...state.shipments];
          shipments[objIndex].ShipmentImages.push(action.payload);
          state.shipments = shipments;
        }
        if (state.shipment.id) {
          let shipment = { ...state.shipment };
          shipment.ShipmentImages.push(action.payload);
          state.shipment = shipment;
        }
      })
      .addCase(addShipmentImage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteShipmentImage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(deleteShipmentImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (state.shipments.length > 0) {
          const shipmentIndex = state.shipments.findIndex(
            (obj) => obj.id === action.payload.shipment_id
          );
          let shipments = [...state.shipments];

          const shipmentImageIndex = shipments[
            shipmentIndex
          ].ShipmentImages.findIndex((obj) => obj.id === action.payload.id);

          shipments[shipmentIndex].ShipmentImages.splice(shipmentImageIndex, 1);
          state.shipments = shipments;
        }

        if (state.shipment.id) {
          let shipment = { ...state.shipment };
          const shipmentImageIndex = state.shipment.ShipmentImages.findIndex(
            (obj) => obj.id === action.payload.id
          );
          shipment.ShipmentImages.splice(shipmentImageIndex, 1);
          state.shipment = shipment;
        }
      })
      .addCase(deleteShipmentImage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addShipmentItem.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(addShipmentItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (state.shipments.length > 0) {
          const objIndex = state.shipments.findIndex(
            (obj) => obj.id === action.payload.shipment_id
          );
          let shipments = [...state.shipments];
          shipments[objIndex].ShipmentItems.push(action.payload);
          state.shipments = shipments;
        }
        if (state.shipment) {
          let shipment = { ...state.shipment };
          shipment.ShipmentItems.push(action.payload);
          state.shipment = shipment;
        }
      })
      .addCase(addShipmentItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateShipmentItem.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(updateShipmentItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (state.shipments.length > 0) {
          const shipmentIndex = state.shipments.findIndex(
            (obj) => obj.id === action.payload.shipment_id
          );
          let shipments = [...state.shipments];

          const shipmentItemIndex = shipments[
            shipmentIndex
          ].ShipmentItems.findIndex((obj) => obj.id === action.payload.id);

          shipments[shipmentIndex].ShipmentItems[shipmentItemIndex] =
            action.payload;
          state.shipments = shipments;
        }

        if (state.shipment.id) {
          let shipment = { ...state.shipment };
          const shipmentItemIndex = state.shipment.ShipmentItems.findIndex(
            (obj) => obj.id === action.payload.id
          );
          shipment.ShipmentItems[shipmentItemIndex] = action.payload;
          state.shipment = shipment;
        }
      })
      .addCase(updateShipmentItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteShipmentItem.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(deleteShipmentItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (state.shipments.length > 0) {
          const shipmentIndex = state.shipments.findIndex(
            (obj) => obj.id === action.payload.shipment_id
          );
          let shipments = [...state.shipments];

          const shipmentItemIndex = shipments[
            shipmentIndex
          ].ShipmentItems.findIndex((obj) => obj.id === action.payload.id);

          shipments[shipmentIndex].ShipmentItems.splice(shipmentItemIndex, 1);
          state.shipments = shipments;
        }

        if (state.shipment.id) {
          let shipment = { ...state.shipment };
          const shipmentItemIndex = state.shipment.ShipmentItems.findIndex(
            (obj) => obj.id === action.payload.id
          );
          shipment.ShipmentItems.splice(shipmentItemIndex, 1);
          state.shipment = shipment;
        }
      })
      .addCase(deleteShipmentItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addShipmentPackage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(addShipmentPackage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (state.shipments.length > 0) {
          const objIndex = state.shipments.findIndex(
            (obj) => obj.id === action.payload.shipment_id
          );
          let shipments = [...state.shipments];
          shipments[objIndex].ShipmentPackages.push(action.payload);
          shipments[objIndex] = resetShipmentTotal(
            shipments[objIndex],
            action.payload
          );
          state.shipments = shipments;
        }
        if (state.shipment.id) {
          let shipment = { ...state.shipment };
          shipment.ShipmentPackages.push(action.payload);
          shipment = resetShipmentTotal(shipment, action.payload);
          state.shipment = shipment;
        }
      })
      .addCase(addShipmentPackage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(bulkAddShipmentPackage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(bulkAddShipmentPackage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(bulkAddShipmentPackage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateShipmentPackage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(updateShipmentPackage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        if (state.shipments.length > 0) {
          const shipmentIndex = state.shipments.findIndex(
            (obj) => obj.id === action.payload.shipment_id
          );
          let shipments = [...state.shipments];

          const shipmentPackageIndex = shipments[
            shipmentIndex
          ].ShipmentPackages.findIndex((obj) => obj.id === action.payload.id);

          shipments[shipmentIndex].ShipmentPackages[shipmentPackageIndex] =
            action.payload;
          shipments[shipmentIndex] = resetShipmentTotal(
            shipments[shipmentIndex],
            action.payload
          );

          state.shipments = shipments;
        }
        if (state.shipment.id) {
          let shipment = { ...state.shipment };
          const shipmentPackageIndex =
            state.shipment.ShipmentPackages.findIndex(
              (obj) => obj.id === action.payload.id
            );

          shipment.ShipmentPackages[shipmentPackageIndex] = action.payload;
          shipment = resetShipmentTotal(shipment, action.payload);
          state.shipment = shipment;
        }
      })
      .addCase(updateShipmentPackage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteShipmentPackage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(deleteShipmentPackage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
        if (state.shipments.length > 0) {
          const shipmentIndex = state.shipments.findIndex(
            (obj) => obj.id === action.payload.shipment_id
          );
          let shipments = [...state.shipments];

          const shipmentItemIndex = shipments[
            shipmentIndex
          ].ShipmentPackages.findIndex((obj) => obj.id === action.payload.id);

          shipments[shipmentIndex].ShipmentPackages.splice(
            shipmentItemIndex,
            1
          );
          shipments[shipmentIndex] = resetShipmentTotal(
            shipments[shipmentIndex],
            action.payload
          );
          state.shipments = shipments;
        }

        if (state.shipment.id) {
          let shipment = { ...state.shipment };
          const shipmentItemIndex = state.shipment.ShipmentPackages.findIndex(
            (obj) => obj.id === action.payload.id
          );
          shipment.ShipmentPackages.splice(shipmentItemIndex, 1);
          shipment = resetShipmentTotal(shipment, action.payload);

          state.shipment = shipment;
        }
      })
      .addCase(deleteShipmentPackage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(bulkDeleteShipmentPackage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(bulkDeleteShipmentPackage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
        if (state.shipments.length > 0) {
          const shipmentIndex = state.shipments.findIndex(
            (obj) => obj.id === action.payload.shipment_id
          );
          let shipments = [...state.shipments];

          shipments[shipmentIndex].ShipmentPackages = shipments[
            shipmentIndex
          ].ShipmentPackages.filter((p) => action.payload.data.some(p.id));

          shipments[shipmentIndex] = resetShipmentTotal(
            shipments[shipmentIndex],
            action.payload
          );
          state.shipments = shipments;
        }

        if (state.shipment.id) {
          let shipment = { ...state.shipment };
          const shipmentItemIndex = state.shipment.ShipmentPackages.findIndex(
            (obj) => obj.id === action.payload.id
          );
          shipment.ShipmentPackages = shipment.ShipmentPackages.filter((p) =>
            action.payload.data.some(p.id)
          );
          shipment = resetShipmentTotal(shipment, action.payload);

          state.shipment = shipment;
        }
      })
      .addCase(bulkDeleteShipmentPackage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addShipmentTracking.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(addShipmentTracking.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        if (state.shipments.length > 0) {
          const objIndex = state.shipments.findIndex(
            (obj) => obj.id === action.payload.shipment_id
          );
          let shipments = [...state.shipments];
          shipments[objIndex].ShipmentTrackings.push(action.payload);
          state.shipments = shipments;
        }
        if (state.shipment.id) {
          let shipment = { ...state.shipment };
          shipment.ShipmentTrackings.push(action.payload);
          state.shipment = shipment;
        }
      })
      .addCase(addShipmentTracking.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateShipmentTracking.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(updateShipmentTracking.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (state.shipments.length > 0) {
          const shipmentIndex = state.shipments.findIndex(
            (obj) => obj.id === action.payload.shipment_id
          );
          let shipments = [...state.shipments];

          const shipmentTrackingIndex = shipments[
            shipmentIndex
          ].ShipmentTrackings.findIndex((obj) => obj.id === action.payload.id);

          shipments[shipmentIndex].ShipmentTrackings[shipmentTrackingIndex] =
            action.payload;
          state.shipments = shipments;
        }
        if (state.shipment.id) {
          let shipment = { ...state.shipment };
          const shipmentTrackingIndex = shipment.ShipmentTrackings.findIndex(
            (obj) => obj.id === action.payload.id
          );

          shipment.ShipmentTrackings[shipmentTrackingIndex] = action.payload;
          state.shipment = shipment;
        }
      })
      .addCase(updateShipmentTracking.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getShipmentLogs.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
        state.message = "";
      })
      .addCase(getShipmentLogs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
        state.logs = action.payload;
      })
      .addCase(getShipmentLogs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createTempShipmentFolder.fulfilled, (state, action) => {
        state.folders.push(action.payload);
      })
      .addCase(getShipmentAddress.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(getShipmentAddress.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.addresses = action.payload;
      })
      .addCase(getShipmentAddress.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = shipmentSlice.actions;
export default shipmentSlice.reducer;
