import { useEffect, useState } from "react";
import { Typography, IconButton, Stack, TextField, Icon } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

function EditableTextLabel(props) {
  const {
    name,
    title,
    titleVariant,
    value,
    valueVariant,
    valueSx,
    fullWidth,
    multiline,
    onSubmit,
    allowEdit,
  } = props;
  const [isHovering, setIsHovering] = useState(false);
  const [tempValue, setTempValue] = useState(value ?? "");
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    setTempValue(value ?? "");
  }, [value]);

  const handleValueCHange = (e) => {
    setTempValue(e.target.value);
  };

  const handleCancelEdit = () => {
    setIsEditable(false);
    setTempValue(value);
    setIsHovering(false);
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleSubmmit = () => {
    onSubmit({ [name]: tempValue });
    setIsEditable(false);
    setIsHovering(false);
  };
  return (
    <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      <Stack direction="row">
        <div onClick={() => !isEditable && setIsEditable(true)}>
          <Typography variant={titleVariant}>{title}</Typography>
        </div>

        {allowEdit && (
          <IconButton
            aria-label="edit"
            size="small"
            sx={{
              my: -1.5,
              visibility: isHovering && !isEditable ? "visible" : "hidden",
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={() => setIsEditable(true)}
          >
            <EditIcon />
          </IconButton>
        )}
      </Stack>
      {!isEditable ? (
        <Typography
          variant={valueVariant}
          sx={valueSx}
          className="display-linebreak"
        >
          {value}
        </Typography>
      ) : (
        <div>
          <TextField
            fullWidth={fullWidth}
            multiline={multiline}
            size="small"
            value={tempValue}
            onChange={handleValueCHange}
          />
          <Stack direction="row">
            <IconButton size="small" onClick={handleSubmmit}>
              <CheckIcon />
            </IconButton>
            <IconButton size="small" onClick={() => handleCancelEdit()}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </div>
      )}
    </div>
  );
}

export default EditableTextLabel;
