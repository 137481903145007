import axios from "axios";

const API_URL = "/api/users/";
const API_URL_EXTERNAL = "/api/users/external/";

// get users
const getUsers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL, config);
  return response.data;
};

// update user
const updateUser = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(API_URL + data.id, data, config);
  return response.data;
};

// create user
const createUser = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL, data, config);
  return response.data;
};

// get external users
const getExternalUsers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL_EXTERNAL, config);
  return response.data;
};

// update External user
const updateExternalUser = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(API_URL_EXTERNAL + data.id, data, config);
  return response.data;
};

// dekete External user
const deleteExternalUser = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL_EXTERNAL + data.id, config);
  return response.data;
};

// create External user
const createExternalUser = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL_EXTERNAL, data, config);
  return response.data;
};

const userService = {
  getUsers,
  createUser,
  updateUser,
  getExternalUsers,
  createExternalUser,
  updateExternalUser,
  deleteExternalUser,
};

export default userService;
