import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import shipmentReducer from "../features/shipments/shipmentSlice";
import customerReducer from "../features/customers/customerSlice";
import notificationReducer from "../features/notifications/notificationSlice";
import userReducer from "../features/users/userSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    shipments: shipmentReducer,
    customers: customerReducer,
    notifications: notificationReducer,
    users: userReducer,
  },
});
