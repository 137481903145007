import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login, reset } from "../features/auth/authSlice";
import { reset as resetShipment } from "../features/shipments/shipmentSlice";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import authService from "../features/auth/authService";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://skytrans.co.id/">
        SKYTRANS LOGISTICS
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [rememberEmail, setRememberEmail] = useState(false);
  const [sendResetPasswordDialogOpen, setSendResetPasswordDialogOpen] =
    useState(false);

  const { email, password } = formData;

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    const lastEmail = localStorage.getItem("email") ?? "";
    const remember = localStorage.getItem("rememberEmail") === "true";
    setFormData({ email: lastEmail, password: "" });
    setRememberEmail(remember);
  }, []);

  useEffect(() => {
    if (isError) {
      console.log(message);
    }

    if (isSuccess || user) {
      const searchParams = new URLSearchParams(location.search);
      const returnUrl = searchParams.get("returnUrl") ?? "/";
      navigate(returnUrl);
      dispatch(reset());

      if (rememberEmail) {
        localStorage.setItem("email", formData.email);
        localStorage.setItem("rememberEmail", rememberEmail.toString());
      } else {
        localStorage.removeItem("email");
        localStorage.removeItem("rememberEmail");
      }
    }
  }, [
    user,
    isError,
    isSuccess,
    message,
    navigate,
    dispatch,
    formData.email,
    rememberEmail,
  ]);

  const handleSendResetPasswordDialogClose = () => {
    setSendResetPasswordDialogOpen(false);
  };

  const handleSendResetPassword = async (e) => {
    e.preventDefault();
    const data = {
      email,
    };

    try {
      const response = await authService.sendResetPassword(data);
      alert("Reset password link has been sent. Check you email");
      setSendResetPasswordDialogOpen(false);
    } catch (error) {
      alert(error.message);
      return;
    }
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email,
      password,
    };

    dispatch(resetShipment());
    dispatch(login(userData));
  };

  return (
    <>
      <CssBaseline />
      <Grid
        container
        component="main"
        justifyContent="center"
        sx={{
          height: "100vh",
        }}
      >
        <Grid
          item
          xs={12}
          sm={8}
          md={4}
          component={Paper}
          elevation={6}
          square
          alignContent="center"
        >
          <Box
            sx={{
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <img id="searchLogo" src="/skylog-logo.png" alt="SKYLOG v2" />
            <Typography variant="body2" sx={{ mt: -5, mb: 4 }}>
              v2.4
            </Typography>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={onSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                error={isError}
                id="email"
                label="Email Address"
                name="email"
                value={email}
                placeholder="Enter your email"
                onChange={onChange}
                autoComplete="email"
                helperText={isError && message}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                error={isError}
                name="password"
                value={password}
                placeholder="Enter password"
                onChange={onChange}
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="remember"
                    color="primary"
                    checked={rememberEmail}
                    onChange={() => setRememberEmail(!rememberEmail)}
                  />
                }
                label="Remember me"
              />
              <LoadingButton
                type="submit"
                fullWidth
                loading={isLoading}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </LoadingButton>
              <Grid container>
                <Grid item xs>
                  <Link
                    variant="body2"
                    onClick={() => setSendResetPasswordDialogOpen(true)}
                  >
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ my: 5 }} />
              <img
                id="skytransLogo"
                src="/SKY2021.png"
                alt="SKYTRANS LOGISTICS"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={sendResetPasswordDialogOpen}
        onClose={handleSendResetPasswordDialogClose}
        PaperProps={{
          component: "form",
          onSubmit: handleSendResetPassword,
        }}
      >
        <DialogTitle>Reset your password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your email address. An email will be sent to you to reset your
            password
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Email Address"
            value={formData.email}
            onChange={onchange}
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendResetPasswordDialogClose}>Cancel</Button>
          <Button onClick={handleSendResetPassword} type="submit">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Login;
