import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getShipments,
  reset,
  getShipmentFolders,
} from "../features/shipments/shipmentSlice";
import queryString from "query-string";

import { Container, CssBaseline, Fab } from "@mui/material";
import Navbar from "../components/Navbar";
import ShipmentCategoryList from "../components/ShipmentCategoryList";
import ShipmentList from "../components/ShipmentList";
import FloatingAddButton from "../components/FloatingAddButton";
import SearchBox from "../components/SearchBox";
import ShipmentFormDialog from "../components/ShipmentFormDialog";
import ScrollTop from "../components/ScrollTop";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { logout, reset as resetAuth } from "../features/auth/authSlice";

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get("q") ?? "";
  const activeFolder = searchParams.get("activeFolder") ?? "";

  const [shipmentDialogFormOpen, setShipmentDialogFormOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const handleShipmentDialogFormOpen = () => {
    setShipmentDialogFormOpen(true);
  };
  const handleShipmentDialogFormClose = () => {
    setShipmentDialogFormOpen(false);
  };

  const handleSearchQueryChanged = (newQuery) => {
    const queryParams = { activeFolder: activeFolder, q: newQuery };
    const qString = queryString.stringify(queryParams);
    navigate(`/?${qString}`);
  };

  const onActiveFolderChanged = (newActiveFolder) => {
    const queryParams = { activeFolder: newActiveFolder };
    const qString = queryString.stringify(queryParams);
    navigate(`/?${qString}`);
  };

  const { user } = useSelector((state) => state.auth);
  const { shipments, folders, isSuccess, isLoading, isError, message } =
    useSelector((state) => state.shipments);

  useEffect(() => {
    if (!user) {
      const queryParams = { returnUrl: location.pathname + location.search };
      const qString = queryString.stringify(queryParams);
      navigate(`/login?${qString}`);
      dispatch(reset());
    }
  }, [user, navigate, dispatch]);

  useEffect(() => {
    if (!isSuccess && isError && message === "Unauthorized") {
      dispatch(reset());
      dispatch(logout());
    }
  }, [user, isError, message, dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(getShipmentFolders());
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (user) {
      setIsAdmin(user.role === "ADMIN" || user.role === "SUPERADMIN");
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (user) {
      let params = {};
      params = { activeFolder: activeFolder, q: query };
      dispatch(getShipments(params));
    }
  }, [user, dispatch, activeFolder, query]);

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          marginBottom: "25px",
        }}
      >
        <Navbar isAdmin={isAdmin} />
        <Container maxWidth="lg">
          <div id="back-to-top-anchor"></div>
          <SearchBox query={query} onQueryChanged={handleSearchQueryChanged} />
          {(!query || query === null || query === "") && (
            <ShipmentCategoryList
              folders={folders}
              activeFolder={activeFolder}
              onActiveFolderChanged={onActiveFolderChanged}
              isAdmin={isAdmin}
            />
          )}

          <ShipmentList
            shipments={shipments}
            onActiveFolderChanged={onActiveFolderChanged}
            loading={isLoading}
            isAdmin={isAdmin}
          />
          {isError && message}
        </Container>

        <ShipmentFormDialog
          open={shipmentDialogFormOpen}
          onClose={handleShipmentDialogFormClose}
        />

        {isAdmin && (
          <FloatingAddButton
            handleShipmentDialogFormOpen={handleShipmentDialogFormOpen}
          />
        )}
        <ScrollTop>
          <Fab sx={isAdmin ? { mr: 8 } : {}} aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
        {/* <Container>
          <img src="/banner.jpg" id="banner" alt="SKYTRANS LOGISTICS" />
        </Container> */}
      </div>
    </>
  );
}

export default Dashboard;
