import axios from "axios";

const API_URL = "/api/users/";

// Login user
const login = async (userData) => {
  const response = await axios.post(API_URL + "login", userData);

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};

// Change user password
const changePassword = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL + "changePassword", data, config);
  return response.data;
};

// get user
const getUser = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + "me", config);
  return response.data;
};

// Reset user password
const resetPassword = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL + "resetPassword", data, config);
  return response.data;
};

// Send Reset user password Request
const sendResetPassword = async (data) => {
  const config = {};

  const response = await axios.post(
    API_URL + "sendResetPassword",
    data,
    config
  );
  return response.data;
};

// Logout user
const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  logout,
  login,
  changePassword,
  resetPassword,
  sendResetPassword,
};

export default authService;
