import axios from "axios";

const API_URL_SHIPMENTS = "/api/shipments/";
const API_URL_SHIPMENT = "/api/shipments/shipment/";
const API_URL_SHIPMENTSTATUS = "/api/shipments/status/";
const API_URL_SHIPMENTIMAGE = "/api/shipments/image/";
const API_URL_SHIPMENTITEM = "/api/shipments/item/";
const API_URL_SHIPMENTPACKAGE = "/api/shipments/package/";
const API_URL_SHIPMENTPACKAGES = "/api/shipments/packages/";
const API_URL_SHIPMENTTRACKING = "/api/shipments/tracking/";
const API_URL_SHIPMENTLOGS = "/api/shipments/logs/";
const API_URL_SHIPMENTADDRESS = "/api/shipments/address/";

// Create new shipment
const createShipment = async (shipmentData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL_SHIPMENTS, shipmentData, config);

  return response.data;
};

// Get shipments
const getShipments = async (query, token) => {
  const config = {
    params: query,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL_SHIPMENTS, config);

  return response.data;
};

// Get shipment
const getShipment = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    API_URL_SHIPMENTS + "/shipment/" + id,
    config
  );

  return response.data;
};

// Get shipment public (no auth)
const getShipmentPublic = async (id) => {
  const response = await axios.get(
    API_URL_SHIPMENTS + "/shipment/public/" + id
  );

  return response.data;
};

// Get shipment folders
const getShipmentFolders = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL_SHIPMENTS + "folders", config);

  return response.data;
};

// Get shipment folders
const updateShipmentFolder = async (folderData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    API_URL_SHIPMENTS + "folders",
    folderData,
    config
  );

  return response.data;
};

// Update shipment
const updateShipment = async (shipmentData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    API_URL_SHIPMENT + shipmentData.id,
    shipmentData,
    config
  );

  return response.data;
};

// Update shipment
const deleteShipment = async (shipment_id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL_SHIPMENT + shipment_id, config);

  return response.data;
};

// Update shipment isCLosed
const updateShipmentIsClosed = async (shipmentData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    API_URL_SHIPMENT + "isClosed/" + shipmentData.id,
    shipmentData,
    config
  );

  return response.data;
};

// Update Shipment Status
const updateShipmentStatus = async (shipmentStatusData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    API_URL_SHIPMENTSTATUS,
    shipmentStatusData,
    config
  );

  return response.data;
};

// Update Shipment Status
const editShipmentStatus = async (shipmentStatusData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    API_URL_SHIPMENTSTATUS,
    shipmentStatusData,
    config
  );

  return response.data;
};

// Create Shipment Status Draft
const createShipmentStatusDraft = async (shipmentStatusData) => {
  const response = await axios.post(
    API_URL_SHIPMENTSTATUS + "draft",
    shipmentStatusData,
    {}
  );

  return response.data;
};

// Delete Shipment Status Draft (Approve / Decline)
const deleteShipmentStatusDraft = async (shipmentStatusData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: shipmentStatusData,
  };

  const response = await axios.delete(
    API_URL_SHIPMENTSTATUS + "draft/" + shipmentStatusData.id,
    config
  );

  return response.data;
};

// Add Shipment Image
const addShipmentImage = async (shipmentImageData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    API_URL_SHIPMENTIMAGE,
    shipmentImageData,
    config
  );

  return response.data;
};

// Delete Shipment Image
const deleteShipmentImage = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL_SHIPMENTIMAGE + id, config);

  return response.data;
};

// Check shipment tracking
const checkShipmentTracking = async (number) => {
  const config = {
    params: { number: number },
  };

  const response = await axios.get(API_URL_SHIPMENTS + "tracking", config);

  return response.data;
};

// Add Shipment Tracking
const addShipmentTracking = async (shipmentTrackingData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    API_URL_SHIPMENTTRACKING,
    shipmentTrackingData,
    config
  );

  return response.data;
};

// Update Shipment Tracking
const updateShipmentTracking = async (shipmentTrackingData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL_SHIPMENTTRACKING}${shipmentTrackingData.id}`,
    shipmentTrackingData,
    config
  );

  return response.data;
};

// Add Shipment Item
const addShipmentItem = async (shipmentItemData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    API_URL_SHIPMENTITEM,
    shipmentItemData,
    config
  );

  return response.data;
};

// Update Shipment Item
const updateShipmentItem = async (shipmentItemData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL_SHIPMENTITEM}${shipmentItemData.id}`,
    shipmentItemData,
    config
  );

  return response.data;
};

// Delete Shipment Item
const deleteShipmentItem = async (shipmentItemData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(
    `${API_URL_SHIPMENTITEM}${shipmentItemData.id}`,
    config
  );

  return response.data;
};

// Add Shipment Package
const addShipmentPackage = async (shipmentPackageData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    API_URL_SHIPMENTPACKAGE,
    shipmentPackageData,
    config
  );

  return response.data;
};

// Bulk Add Shipment Package
const bulkAddShipmentPackage = async (shipmentPackageData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    API_URL_SHIPMENTPACKAGES,
    shipmentPackageData,
    config
  );

  return response.data;
};

// Update Shipment Package
const updateShipmentPackage = async (shipmentPackageData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL_SHIPMENTPACKAGE}${shipmentPackageData.id}`,
    shipmentPackageData,
    config
  );

  return response.data;
};

// Delete Shipment Package
const deleteShipmentPackage = async (shipmentPackageData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(
    `${API_URL_SHIPMENTPACKAGE}${shipmentPackageData.id}`,
    config
  );

  return response.data;
};

// Bulk Delete Shipment Package
const bulkDeleteShipmentPackage = async (shipmentPackageData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: shipmentPackageData,
  };

  const response = await axios.delete(API_URL_SHIPMENTPACKAGES, config);

  return response.data;
};

const generateDocument = async (data, token) => {
  const response = await axios.post("/api/shipments/document/cn", data, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/pdf",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

const generateQr = async (shipmentId, token) => {
  const response = await axios.get("/api/shipments/document/qr/" + shipmentId, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/pdf",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

const getShipmentLogs = async (shipmentId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_URL_SHIPMENTLOGS}${shipmentId}`,
    config
  );

  return response.data;
};

// Get shipment address
const getShipmentAddress = async (query, token) => {
  const config = {
    params: query,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL_SHIPMENTADDRESS, config);

  return response.data;
};

const shipmentService = {
  createShipment,
  getShipments,
  getShipment,
  getShipmentPublic,
  updateShipment,
  deleteShipment,
  createShipmentStatusDraft,
  deleteShipmentStatusDraft,
  updateShipmentStatus,
  editShipmentStatus,
  addShipmentImage,
  deleteShipmentImage,
  getShipmentFolders,
  updateShipmentFolder,
  checkShipmentTracking,
  addShipmentTracking,
  updateShipmentTracking,
  addShipmentItem,
  updateShipmentItem,
  deleteShipmentItem,
  addShipmentPackage,
  bulkAddShipmentPackage,
  updateShipmentPackage,
  deleteShipmentPackage,
  bulkDeleteShipmentPackage,
  generateDocument,
  generateQr,
  updateShipmentIsClosed,
  getShipmentLogs,
  getShipmentAddress,
};

export default shipmentService;
