import React, { useState } from "react";
import {
  Collapse,
  TableRow,
  TableCell,
  IconButton,
  TextField,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { NumericFormat } from "react-number-format";
import ItemTable from "./ItemTable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function PackageTableRow(props) {
  const {
    i,
    shipment,
    row,
    rowOpened,
    editableRowId,
    setEditableRowId,
    handleCollapsedPackageRow,
    setFormData,
    formData,
    handleFormDataChange,
    handleDeletePackage,
    handleSubmitFormData,
    calculateVolume,
    calculateTotalWeight,
    isEditable,
  } = props;

  const getPackageItems = (package_id) => {
    return shipment.ShipmentItems
      ? shipment.ShipmentItems.filter((item) => item.package_id === package_id)
      : [];
  };

  const handleCollapse = () => {
    const newState = !rowOpened;
    handleCollapsedPackageRow(newState, i);
  };

  return (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleCollapse}
          >
            {rowOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ minWidth: 50 }}>
          {row.id === editableRowId ? (
            <TextField
              size="small"
              fullWidth
              variant="standard"
              name="packageNo"
              autoFocus
              value={formData.packageNo}
              onChange={handleFormDataChange}
            />
          ) : (
            row.packageNo
          )}
        </TableCell>
        <TableCell sx={{ minWidth: 80 }}>
          {row.id === editableRowId ? (
            <TextField
              size="small"
              fullWidth
              variant="standard"
              name="unit"
              value={formData.unit}
              onChange={handleFormDataChange}
            />
          ) : (
            row.unit
          )}
        </TableCell>
        <TableCell sx={{ minWidth: 80 }}>
          {row.id === editableRowId ? (
            <NumericFormat
              customInput={TextField}
              size="small"
              fullWidth
              decimalSeparator=","
              thousandSeparator="."
              valueIsNumericString={true}
              variant="standard"
              name="qty"
              value={formData.qty}
              onValueChange={(values, sourceInfo) => {
                setFormData((prevState) => ({
                  ...prevState,
                  qty: values.value,
                }));

                calculateVolume(
                  formData.length,
                  formData.width,
                  formData.height,
                  values.value
                );
                calculateTotalWeight(formData.unitWeight, values.value);
              }}
            />
          ) : (
            <NumericFormat
              displayType="text"
              decimalSeparator=","
              valueIsNumericString={true}
              thousandSeparator="."
              value={row.qty}
            />
          )}
        </TableCell>

        <TableCell sx={{ minWidth: 60 }}>
          {row.id === editableRowId ? (
            <NumericFormat
              customInput={TextField}
              size="small"
              fullWidth
              decimalSeparator=","
              thousandSeparator="."
              valueIsNumericString={true}
              variant="standard"
              name="length"
              value={formData.length}
              onValueChange={(values, sourceInfo) => {
                setFormData((prevState) => ({
                  ...prevState,
                  length: values.value,
                }));

                calculateVolume(
                  formData.qty,
                  formData.width,
                  formData.height,
                  values.value
                );
              }}
            />
          ) : (
            <NumericFormat
              displayType="text"
              decimalSeparator=","
              valueIsNumericString={true}
              thousandSeparator="."
              value={row.length}
            />
          )}
        </TableCell>
        <TableCell sx={{ minWidth: 60 }}>
          {row.id === editableRowId ? (
            <NumericFormat
              customInput={TextField}
              size="small"
              fullWidth
              decimalSeparator=","
              thousandSeparator="."
              valueIsNumericString={true}
              variant="standard"
              name="width"
              value={formData.width}
              onValueChange={(values, sourceInfo) => {
                setFormData((prevState) => ({
                  ...prevState,
                  width: values.value,
                }));

                calculateVolume(
                  formData.length,
                  formData.qty,
                  formData.height,
                  values.value
                );
              }}
            />
          ) : (
            <NumericFormat
              displayType="text"
              decimalSeparator=","
              valueIsNumericString={true}
              thousandSeparator="."
              value={row.width}
            />
          )}
        </TableCell>
        <TableCell sx={{ minWidth: 60 }}>
          {row.id === editableRowId ? (
            <NumericFormat
              customInput={TextField}
              size="small"
              fullWidth
              decimalSeparator=","
              thousandSeparator="."
              valueIsNumericString={true}
              variant="standard"
              name="height"
              value={formData.height}
              onValueChange={(values, sourceInfo) => {
                setFormData((prevState) => ({
                  ...prevState,
                  height: values.value,
                }));

                calculateVolume(
                  formData.length,
                  formData.width,
                  formData.qty,
                  values.value
                );
              }}
            />
          ) : (
            <NumericFormat
              displayType="text"
              decimalSeparator=","
              valueIsNumericString={true}
              thousandSeparator="."
              value={row.height}
            />
          )}
        </TableCell>

        <TableCell sx={{ minWidth: 60 }}>
          {row.id === editableRowId ? (
            <NumericFormat
              customInput={TextField}
              size="small"
              fullWidth
              decimalSeparator=","
              thousandSeparator="."
              valueIsNumericString={true}
              variant="standard"
              name="volume"
              value={formData.volume}
              onValueChange={(values, sourceInfo) => {
                setFormData((prevState) => ({
                  ...prevState,
                  volume: values.value,
                }));
              }}
            />
          ) : (
            <NumericFormat
              displayType="text"
              decimalSeparator=","
              valueIsNumericString={true}
              thousandSeparator="."
              value={row.volume}
            />
          )}
        </TableCell>

        <TableCell sx={{ minWidth: 80 }}>
          {row.id === editableRowId ? (
            <NumericFormat
              customInput={TextField}
              size="small"
              fullWidth
              decimalSeparator=","
              thousandSeparator="."
              valueIsNumericString={true}
              variant="standard"
              name="unitWeight"
              value={formData.unitWeight}
              onValueChange={(values, sourceInfo) => {
                setFormData((prevState) => ({
                  ...prevState,
                  unitWeight: values.value,
                }));

                calculateTotalWeight(formData.qty, values.value);
              }}
            />
          ) : (
            <NumericFormat
              displayType="text"
              decimalSeparator=","
              valueIsNumericString={true}
              thousandSeparator="."
              value={row.unitWeight}
            />
          )}
        </TableCell>

        <TableCell sx={{ minWidth: 80 }}>
          {row.id === editableRowId ? (
            <NumericFormat
              customInput={TextField}
              size="small"
              fullWidth
              decimalSeparator=","
              thousandSeparator="."
              valueIsNumericString={true}
              variant="standard"
              name="totalWeight"
              value={formData.totalWeight}
              onValueChange={(values, sourceInfo) => {
                setFormData((prevState) => ({
                  ...prevState,
                  totalWeight: values.value,
                }));
              }}
            />
          ) : (
            <NumericFormat
              displayType="text"
              decimalSeparator=","
              valueIsNumericString={true}
              thousandSeparator="."
              value={row.totalWeight}
            />
          )}
        </TableCell>

        <TableCell sx={{ minWidth: 80 }}>
          {row.id === editableRowId ? (
            <NumericFormat
              customInput={TextField}
              size="small"
              fullWidth
              decimalSeparator=","
              thousandSeparator="."
              valueIsNumericString={true}
              variant="standard"
              name="volumeWeight"
              value={formData.volumeWeight}
              onValueChange={(values, sourceInfo) => {
                setFormData((prevState) => ({
                  ...prevState,
                  volumeWeight: values.value,
                }));
              }}
            />
          ) : (
            <NumericFormat
              displayType="text"
              decimalSeparator=","
              valueIsNumericString={true}
              thousandSeparator="."
              value={row.volumeWeight}
            />
          )}
        </TableCell>

        <TableCell sx={{ minWidth: 150 }}>
          {row.id === editableRowId ? (
            <Stack direction="row" alignItems="baseline">
              <NumericFormat
                customInput={TextField}
                size="small"
                fullWidth
                decimalSeparator=","
                thousandSeparator="."
                valueIsNumericString={true}
                variant="standard"
                name="chargeable"
                value={formData.chargeable}
                onValueChange={(values, sourceInfo) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    chargeable: values.value,
                  }));
                }}
              />
              <Select
                value={formData.chargeableUnit}
                variant="standard"
                name="chargeableUnit"
                sx={{ ml: 1, minWidth: 60 }}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    chargeableUnit: e.target.value,
                  }))
                }
              >
                <MenuItem value="kg">kg</MenuItem>
                <MenuItem value="cbm">cbm</MenuItem>
              </Select>
            </Stack>
          ) : (
            <Stack direction="row" gap={0.5}>
              <NumericFormat
                displayType="text"
                decimalSeparator=","
                valueIsNumericString={true}
                thousandSeparator="."
                value={row.chargeable}
              />
              {row.chargeableUnit}
            </Stack>
          )}
        </TableCell>

        <TableCell sx={{ minWidth: 120 }}>
          {row.id === editableRowId ? (
            <TextField
              size="small"
              fullWidth
              multiline
              variant="standard"
              name="note"
              value={formData.note}
              onChange={handleFormDataChange}
            />
          ) : (
            row.note
          )}
        </TableCell>
        {isEditable && (
          <TableCell align="right">
            {row.id === editableRowId ? (
              <Stack direction="row" justifyContent="flex-end">
                <IconButton aria-label="submit" onClick={handleSubmitFormData}>
                  <CheckIcon />
                </IconButton>
                <IconButton
                  aria-label="cancel"
                  onClick={() => setEditableRowId(null)}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            ) : (
              <Stack direction="row" justifyContent="flex-end">
                <IconButton
                  aria-label="Edit"
                  onClick={() => setEditableRowId(row.id)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="Delete"
                  onClick={() => handleDeletePackage(row)}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            )}
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={rowOpened} timeout="auto" unmountOnExit sx={{ py: 1 }}>
            <ItemTable
              items={getPackageItems(row.id)}
              shipment={shipment}
              package_id={row.id}
              isEditable={isEditable}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default PackageTableRow;
