import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteShipmentImage,
  updateShipment,
} from "../features/shipments/shipmentSlice";
import ShipmentService from "../features/shipments/shipmentService";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import { Add } from "@mui/icons-material";
import ShipmentImageFormDialog from "./ShipmentImageFormDialog";
import TrackingTable from "./TrackingTable";
import FolderIcon from "@mui/icons-material/Folder";
import ShipmentStatusChip from "./ShipmentStatusChip";
import EditableTextLabel from "./EditableTextLabel";
import PackageTable from "./PackageTable";
import EditableNumericTextLabel from "./EditableNumericTextLabel";
import ShipmentCategoryList from "./ShipmentCategoryList";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import EditableCustomerTextLabel from "./EditableCustomerTextLabel";

const ShipmentImageItem = (props) => {
  const { image, i, onDelete } = props;
  const imglink = "https://lh3.google.com/u/0/d/" + image.link.split("/")[5];

  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleImageContextMenuClose = () => {
    setContextMenu(null);
  };
  return (
    <Grid item key={i}>
      <div onContextMenu={handleContextMenu}>
        <a target="blank" href={image.link}>
          <Tooltip title={image.name}>
            <img
              height={64}
              width={64}
              alt={image.name}
              src={imglink}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/pdf.png";
              }}
            />
          </Tooltip>
        </a>
      </div>
      <Menu
        open={contextMenu !== null}
        onClose={handleImageContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={() => onDelete(image)}>Delete</MenuItem>
      </Menu>
    </Grid>
  );
};

const ShipmentItemCard = (props) => {
  const {
    item,
    onActiveFolderChanged,
    onShipmentStatusClick,
    expand,
    shomMore,
    isAdmin,
  } = props;
  const [expanded, setExpanded] = useState(expand ?? false);
  const [addImageDialogOpen, setAddImageDialogOpen] = useState(false);
  const [printMenuAnchorEl, setPrintMenuAnchorEl] = useState(null);
  const [folderDialogOpen, setFolderDialogOPen] = useState(false);
  const [moveToFolder, setMoveToFolder] = useState(item.folder);
  const printMenuOpen = Boolean(printMenuAnchorEl);
  const isEditable = isAdmin && !item.isClosed;

  const dispatch = useDispatch();
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("md"));

  const { user } = useSelector((state) => state.auth);
  const { folders } = useSelector((state) => state.shipments);

  const latestStatus = item.latestStatus;

  const handleMoveToFolderSelected = (newSelectedFolder) => {
    setMoveToFolder(newSelectedFolder);
  };

  const handleFolderDialogOpen = () => {
    setMoveToFolder(item.folder);
    setFolderDialogOPen(true);
  };

  const handleMoveToFolderSave = () => {
    const newData = {
      ...item,
      folder: moveToFolder,
    };
    handleUpdateShipment(newData);
    setFolderDialogOPen(false);
    onActiveFolderChanged(moveToFolder);
  };

  const handleFolderDialogClose = () => {
    setFolderDialogOPen(false);
  };

  const handlePrintClick = (event) => {
    setPrintMenuAnchorEl(event.currentTarget);
  };
  const handlePrintClose = () => {
    setPrintMenuAnchorEl(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleAddImageOpen = () => {
    setAddImageDialogOpen(true);
  };

  const handleAddImageClose = () => {
    setAddImageDialogOpen(false);
  };

  const handleDeleteImage = (image) => {
    dispatch(deleteShipmentImage(image.id));
  };

  const handleUpdateShipment = (newData) => {
    dispatch(updateShipment({ ...newData, id: item.id }));
  };

  const handlePrint = async (template) => {
    let response = {};

    if (template === 0) {
      response = await ShipmentService.generateQr(item.id, user.token);
    } else {
      response = await ShipmentService.generateDocument(
        {
          shipment_id: item.id,
          template,
        },
        user.token
      );
    }

    if (response.data) {
      var file = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }

    setPrintMenuAnchorEl(null);
  };

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item xs={11} sx={{ mb: 2 }}>
            <Stack
              direction="row"
              useFlexGap
              flexWrap="wrap"
              alignItems="center"
            >
              <Typography
                variant="subtitle1"
                className="display-linebreak"
                sx={{ mt: 0.3, mr: 1 }}
              >
                <Link
                  color="inherit"
                  underline="hover"
                  href={"/shipment/" + item.id}
                >
                  {item.shipmentNo || item.session_id}{" "}
                </Link>
              </Typography>

              {latestStatus && (
                <ShipmentStatusChip
                  status={latestStatus.status}
                  variant="filled"
                  color={latestStatus.type}
                  size="medium"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    onShipmentStatusClick(item, latestStatus);
                  }}
                />
              )}
              {item.folder !== "/" && (
                <Link
                  color="GrayText"
                  underline="hover"
                  onClick={() => onActiveFolderChanged(item.folder)}
                  sx={{ mt: 0.5, cursor: "pointer" }}
                >
                  <Stack direction="row" alignItems="center">
                    <FolderIcon sx={{ mr: 1 }} />
                    <Typography variant="body2" sx={{ mt: 0.2 }}>
                      {item.folder}
                    </Typography>
                  </Stack>
                </Link>
              )}
              {isAdmin && (
                <IconButton
                  aria-label="Move to"
                  onClick={handleFolderDialogOpen}
                >
                  <DriveFileMoveIcon />
                </IconButton>
              )}
            </Stack>
          </Grid>
          {isAdmin && (
            <Grid item textAlign="right" xs={1}>
              <IconButton aria-label="print" onClick={handlePrintClick}>
                <PrintIcon />
              </IconButton>
              <Menu
                id="print-menu"
                anchorEl={printMenuAnchorEl}
                open={printMenuOpen}
                onClose={handlePrintClose}
                MenuListProps={{
                  "aria-labelledby": "print-button",
                }}
              >
                <MenuItem onClick={() => handlePrint(1)}>
                  Template Kemenkes
                </MenuItem>
                <MenuItem onClick={() => handlePrint(2)}>
                  Template Cargill
                </MenuItem>
                <MenuItem onClick={() => handlePrint(3)}>
                  Template Standard
                </MenuItem>
                <MenuItem onClick={() => handlePrint(0)}>QR Code</MenuItem>
              </Menu>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={1} sx={{ maxWidth: 1200 }}>
          <Grid item xs={12} md={7} lg={8}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                {/* <Typography variant="subtitle2">Customer</Typography>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  {item.Customer && item.Customer.name}
                </Typography> */}
                <EditableCustomerTextLabel
                  title="Customer"
                  name="customer"
                  fullWidth
                  multiline
                  titleVariant="subtitle2"
                  value={item.Customer}
                  valueVariant="subtitle1"
                  valueSx={{ mb: 1 }}
                  onSubmit={handleUpdateShipment}
                  allowEdit={isEditable}
                />
                <EditableTextLabel
                  title="From"
                  name="from"
                  fullWidth
                  multiline
                  titleVariant="subtitle2"
                  value={item.from || "-"}
                  valueVariant="subtitle1"
                  valueSx={{ mb: 1 }}
                  onSubmit={handleUpdateShipment}
                  allowEdit={isEditable}
                />
                <EditableTextLabel
                  title="To"
                  name="to"
                  fullWidth
                  multiline
                  titleVariant="subtitle2"
                  value={item.to}
                  valueVariant="subtitle1"
                  valueSx={{ mb: 1 }}
                  onSubmit={handleUpdateShipment}
                  allowEdit={isEditable}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <EditableTextLabel
                  title="Transportation Type"
                  name="line"
                  fullWidth
                  multiline
                  titleVariant="subtitle2"
                  value={item.line}
                  valueVariant="subtitle1"
                  valueSx={{ mb: 1 }}
                  onSubmit={handleUpdateShipment}
                  allowEdit={isEditable}
                />
                <EditableTextLabel
                  title="Commodity"
                  name="commodity"
                  fullWidth
                  multiline
                  titleVariant="subtitle2"
                  value={item.commodity || "-"}
                  valueVariant="subtitle1"
                  valueSx={{ mb: 1 }}
                  onSubmit={handleUpdateShipment}
                  allowEdit={isEditable}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {item.ShipmentImages &&
                    item.ShipmentImages.map((image, i) => (
                      <ShipmentImageItem
                        key={i}
                        image={image}
                        i={i}
                        onDelete={isAdmin ? handleDeleteImage : null}
                      />
                    ))}
                  {isEditable && (
                    <Grid item>
                      <Button
                        variant="outlined"
                        sx={{ height: 64, width: 64 }}
                        onClick={handleAddImageOpen}
                      >
                        <Add />
                      </Button>
                      <ShipmentImageFormDialog
                        shipment={item}
                        open={addImageDialogOpen}
                        onClose={handleAddImageClose}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {!smallscreen && (
            <Grid item xs={12} md={5} lg={4}>
              <TrackingTable shipment={item} isEditable={isEditable} />
            </Grid>
          )}
        </Grid>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container>
              {smallscreen && (
                <Grid item xs={12}>
                  <TrackingTable shipment={item} isEditable={isEditable} />
                </Grid>
              )}
              {/* <Grid item xs={12}>
                <ItemTable shipment={item} />
              </Grid> */}
              <Grid item xs={12}>
                <PackageTable shipment={item} isEditable={isEditable} />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item>
                <EditableNumericTextLabel
                  title="Total Qty"
                  name="totalItemQty"
                  suffix="Ȼ"
                  titleVariant="subtitle2"
                  value={item.totalItemQty}
                  valueVariant="subtitle1"
                  valueSx={{ mb: 1 }}
                  onSubmit={handleUpdateShipment}
                  allowEdit={isEditable}
                />
              </Grid>
              <Grid item>
                <EditableNumericTextLabel
                  title="Total Volume"
                  name="totalItemVolume"
                  suffix="cbm"
                  titleVariant="subtitle2"
                  value={item.totalItemVolume}
                  valueVariant="subtitle1"
                  valueSx={{ mb: 1 }}
                  onSubmit={handleUpdateShipment}
                  allowEdit={isEditable}
                />
              </Grid>
              <Grid item>
                <EditableNumericTextLabel
                  title="Total Berat"
                  suffix="kg"
                  name="totalItemWeight"
                  fullWidth
                  titleVariant="subtitle2"
                  value={item.totalItemWeight}
                  valueVariant="subtitle1"
                  valueSx={{ mb: 1 }}
                  onSubmit={handleUpdateShipment}
                  allowEdit={isEditable}
                />
              </Grid>

              <Grid item>
                <EditableNumericTextLabel
                  title="Total Berat Volume"
                  name="totalItemVolumeWeight"
                  suffix="kg"
                  titleVariant="subtitle2"
                  value={item.totalItemVolumeWeight}
                  valueVariant="subtitle1"
                  valueSx={{ mb: 1 }}
                  onSubmit={handleUpdateShipment}
                  allowEdit={isEditable}
                />
              </Grid>
              <Grid item>
                <EditableNumericTextLabel
                  title="Total Ditagih"
                  name="totalChargeable"
                  suffix={
                    item.ShipmentPackages && item.ShipmentPackages.length > 0
                      ? item.ShipmentPackages[0].chargeableUnit
                      : ""
                  }
                  titleVariant="subtitle2"
                  value={item.totalChargeable}
                  valueVariant="subtitle1"
                  valueSx={{ mb: 1 }}
                  onSubmit={handleUpdateShipment}
                  allowEdit={isEditable}
                />
              </Grid>
              <Grid item>
                <EditableNumericTextLabel
                  title="Nilai Barang"
                  name="totalItemValue"
                  useCurrency={true}
                  currencyName="totalItemValueCurrency"
                  currencyValue={item.totalItemValueCurrency}
                  forceDecimal
                  titleVariant="subtitle2"
                  value={item.totalItemValue}
                  valueVariant="subtitle1"
                  valueSx={{ mb: 1 }}
                  onSubmit={handleUpdateShipment}
                  allowEdit={isEditable}
                />
              </Grid>
              <Grid item xs={12}>
                <EditableTextLabel
                  title="Note / Catatan"
                  name="note"
                  titleVariant="subtitle2"
                  value={item.note}
                  valueVariant="body1"
                  valueSx={{ mb: 1 }}
                  onSubmit={handleUpdateShipment}
                  allowEdit={isEditable}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
        <Dialog open={folderDialogOpen} onClose={handleFolderDialogClose}>
          <DialogTitle>Move Shipment</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Select the folder to move the shipment
            </DialogContentText>
            <ShipmentCategoryList
              folders={folders}
              activeFolder={moveToFolder === "/" ? null : moveToFolder}
              onActiveFolderChanged={handleMoveToFolderSelected}
              isAdmin={isAdmin}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleFolderDialogClose}>Cancel</Button>
            <Button onClick={handleMoveToFolderSave}>MOVE HERE</Button>
          </DialogActions>
        </Dialog>
      </CardContent>
      {shomMore && (
        <CardActions>
          <Button size="small" onClick={handleExpandClick}>
            {expanded ? "SHOW LESS" : "SHOW MORE"}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default ShipmentItemCard;
