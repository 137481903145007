import axios from "axios";

const API_URL = "/api/notifications/";

// Get notifications
const getNotifications = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL, config);

  return response.data;
};

// Get notifications per shipment / shipment logs
const getShipmentLogs = async (shipment_id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      shipment_id,
      getAll: true,
    },
  };

  const response = await axios.get(API_URL, config);

  return response.data;
};

const readNotification = async (notificationData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    API_URL + notificationData.id,
    notificationData,
    config
  );

  return response.data;
};

const readAllNotifications = async (notificationData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(API_URL, notificationData, config);

  return response.data;
};

const notificationService = {
  getNotifications,
  getShipmentLogs,
  readNotification,
  readAllNotifications,
};

export default notificationService;
