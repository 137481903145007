import Timeline from "@mui/lab/Timeline";
import { useDispatch } from "react-redux";
import { deleteShipmentStatusDraft } from "../features/shipments/shipmentSlice";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import PlaceIcon from "@mui/icons-material/Place";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Stack,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import TrackingStatusFormDialog from "./TrackingStatusFormDialog";
import EditIcon from "@mui/icons-material/Edit";
import { Close, WhatsApp } from "@mui/icons-material";

const googleMaps = "https://maps.google.com/?q=";
const tooltIpSlotProp = {
  popper: {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -14],
        },
      },
    ],
  },
};

const TimelineIcon = (props) => {
  const { type } = props;
  switch (type) {
    case "warning":
      return <WarningIcon />;
    case "error":
      return <ErrorIcon />;
    default:
      return <CheckIcon />;
  }
};

export default function TrackingStatusTimeline(props) {
  const { statuses, shipment, isEditable, draft } = props;
  const [sortedStatuses, setSortedStatuses] = useState([]);
  const [editedStatus, setEditedStatus] = useState({});
  const [editStatusFormDialogOpen, setEditStatusFormDialogOpen] =
    useState(false);
  const phoneRgx = /(?:[+() ]*\d){10,13}/gm;

  const dispatch = useDispatch();
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    timezone: "Asia/Jakarta",
  };

  const handleSelectEditStatus = (selectedStatus) => {
    setEditedStatus(selectedStatus);
    setEditStatusFormDialogOpen(true);
  };

  const handleApproveStatusDraft = (status_id) => {
    const data = { id: status_id, approve: true };
    dispatch(deleteShipmentStatusDraft(data));
  };

  const handleDeclineStatusDraft = (status_id) => {
    const data = { id: status_id, approve: false };
    dispatch(deleteShipmentStatusDraft(data));
  };

  useEffect(() => {
    let sorted = [...statuses];
    sorted.reverse();
    setSortedStatuses(sorted);
  }, [statuses]);
  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
        mt: 0,
      }}
    >
      <TrackingStatusFormDialog
        shipment={shipment}
        open={editStatusFormDialogOpen}
        onClose={() => {
          setEditStatusFormDialogOpen(false);
        }}
        onUpdate={() => setEditStatusFormDialogOpen(false)}
        edit={true}
        editedStatus={editedStatus}
      />
      {sortedStatuses &&
        sortedStatuses.map((status, i) => {
          if (status.geolocation) {
            return (
              <TimelineItem key={i}>
                <TimelineSeparator>
                  <a href={`${googleMaps}${status.geolocation}`} target="blank">
                    <TimelineDot
                      color={status.status === "DELIVERED" ? "success" : "info"}
                    >
                      <PlaceIcon />
                    </TimelineDot>
                  </a>
                  {i < sortedStatuses.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Stack>
                    <Typography variant="h6">
                      {status.title}
                      {isEditable && (
                        <IconButton
                          aria-label="Edit"
                          onClick={() => handleSelectEditStatus(status)}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </Typography>
                    <Box>
                      {status.description &&
                        status.description.split("-").map((part, i) => {
                          const str = part;
                          const matches = str?.match(phoneRgx);
                          const phones = matches?.map(function (s) {
                            if (s.trim().startsWith("0")) {
                              return `+62${s.trim().substring(1)}`;
                            } else {
                              return s.trim();
                            }
                          });
                          if (matches && matches.length > 0) {
                            return (
                              <Box key={i} spacing={1} alignItems="center">
                                {part}
                                <Link
                                  href={`https://wa.me/${phones[0]}`}
                                  target="blank"
                                >
                                  <WhatsApp sx={{ mb: -0.5 }} />
                                </Link>
                              </Box>
                            );
                          } else {
                            return (
                              <Typography key={i} variant="body2">
                                {part}
                              </Typography>
                            );
                          }
                        })}
                    </Box>
                    <Tooltip
                      title={status.updatedAt}
                      placement="bottom-start"
                      slotProps={tooltIpSlotProp}
                    >
                      <Typography variant="subtitle2">
                        {new Date(status.timestamps).toLocaleTimeString(
                          "id-ID",
                          dateOptions
                        )}
                        - {status.updatedBy}
                      </Typography>
                    </Tooltip>
                    <Link
                      href={`${googleMaps}${status.geolocation}`}
                      target="_blank"
                      rel="noreferer"
                      color="inherit"
                      underline="hover"
                    >
                      {status.city}
                    </Link>
                    {draft && (
                      <Stack direction="row" gap={1}>
                        <Button
                          size="small"
                          color="success"
                          startIcon={<CheckIcon />}
                          onClick={() => handleApproveStatusDraft(status.id)}
                        >
                          Approve
                        </Button>
                        <Button
                          size="small"
                          color="error"
                          startIcon={<Close />}
                          onClick={() => handleDeclineStatusDraft(status.id)}
                        >
                          Decline
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                </TimelineContent>
              </TimelineItem>
            );
          } else {
            return (
              <TimelineItem key={i}>
                <TimelineSeparator>
                  <TimelineDot color={i === 0 ? status.type : "grey"}>
                    <TimelineIcon type={status.type} />
                  </TimelineDot>
                  {i < sortedStatuses.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant="h6">
                    {status.title}
                    {!["NEW", "COMPLETED", "REOPENED"].some(
                      (s) => s === status.status
                    ) &&
                      isEditable && (
                        <IconButton
                          aria-label="Edit"
                          onClick={() => handleSelectEditStatus(status)}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                  </Typography>
                  <Box>
                    {status.description &&
                      status.description.split("-").map((part, i) => {
                        const str = part;
                        const matches = str?.match(phoneRgx);
                        const phones = matches?.map(function (s) {
                          if (s.trim().startsWith("0")) {
                            return `+62${s.trim().substring(1)}`;
                          } else {
                            return s.trim();
                          }
                        });
                        if (matches && matches.length > 0) {
                          return (
                            <Box key={i} spacing={1} alignItems="center">
                              {part}
                              <Link
                                href={`https://wa.me/${phones[0]}`}
                                target="blank"
                              >
                                <WhatsApp sx={{ mb: -0.5 }} />
                              </Link>
                            </Box>
                          );
                        } else {
                          return (
                            <Typography key={i} variant="body2">
                              {part}
                            </Typography>
                          );
                        }
                      })}
                  </Box>
                  <Tooltip
                    placement="bottom-start"
                    title={
                      "Updated at -" +
                      new Date(status.updatedAt).toLocaleTimeString(
                        "id-ID",
                        dateOptions
                      )
                    }
                    slotProps={tooltIpSlotProp}
                  >
                    <Typography variant="subtitle2">
                      {new Date(status.timestamps).toLocaleTimeString(
                        "id-ID",
                        dateOptions
                      )}{" "}
                      - {status.updatedBy}
                    </Typography>
                  </Tooltip>
                  {draft && (
                    <Stack direction="row" gap={1}>
                      <Button
                        size="small"
                        color="success"
                        startIcon={<CheckIcon />}
                        onClick={() => handleApproveStatusDraft(status.id)}
                      >
                        Approve
                      </Button>
                      <Button
                        size="small"
                        color="error"
                        startIcon={<Close />}
                        onClick={() => handleDeclineStatusDraft(status.id)}
                      >
                        Decline
                      </Button>
                    </Stack>
                  )}
                </TimelineContent>
              </TimelineItem>
            );
          }
        })}
    </Timeline>
  );
}
