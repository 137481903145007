import { useEffect, useState } from "react";
import { Container } from "@mui/system";
import {
  Chip,
  InputAdornment,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchBox(props) {
  const { query, onQueryChanged } = props;
  const [formData, setFormData] = useState({ searchText: "" });

  useEffect(() => {
    setFormData((prevState) => ({ ...prevState, searchText: query }));
  }, [query]);

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClearSearch = () => {
    onQueryChanged(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onQueryChanged(formData.searchText);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: "100%",
        pb: 2,
      }}
    >
      <Container maxWidth="md">
        <img id="searchLogo" src="/SKY2021.png" alt="SKYTRANS LOGISTICS" />
        <Typography variant="body2" sx={{ mt: -5, mb: 4 }} textAlign="center">
          v2.4
        </Typography>
        <TextField
          fullWidth
          label="Tracking Number"
          id="fullWidth"
          placeholder="CN / SBBK / SMU / MAWB / BL"
          value={formData.searchText}
          name="searchText"
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {query && query !== null && query !== "" && (
          <Box sx={{ flex: "1", textAlign: "center" }}>
            <Chip
              label="Clear Search"
              sx={{ m: 2 }}
              onClick={handleClearSearch}
              onDelete={handleClearSearch}
            />
          </Box>
        )}
      </Container>
    </Box>
  );
}
