import {
  Box,
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { Add, Delete, Edit } from "@mui/icons-material";
import {
  createExternalUser,
  createUser,
  deleteExternalUser,
  getExternalUsers,
  getUsers,
  reset,
  updateExternalUser,
  updateUser,
} from "../features/users/userSlice";
import { logout } from "../features/auth/authSlice";
import CustomerTextBox from "../components/CustomerTextBox";

const initialUserState = {
  id: null,
  username: "",
  email: "",
  role: "",
  customer_id: "",
  Customer: null,
  isActive: true,
};

const initialExternalUserState = {
  id: null,
  username: "",
  phone: "",
  description: "",
  isActive: true,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [userFormData, setUserFormData] = useState(initialUserState);
  const [externalUserFormData, setExternalUserFormData] =
    useState(initialUserState);
  const [userFormDialogOpen, setUserFormDialogOpen] = useState(false);
  const [externalUserFormDialogOpen, setExternalUserFormDialogOpen] =
    useState(false);
  const [
    externalUserDeleteConfirmationDialogOpen,
    setExternalUserDeleteConfirmationDialogOpen,
  ] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [userSearchQuery, setUserSearchQuery] = useState("");
  const [externalUserSearchQuery, setExternalUserSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredExternalUsers, setFilteredExternalUsers] = useState([]);
  const [externalUserSortBy, setExternalUserSortBy] = useState("username");

  const handleSelectedTabChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
  };

  const { user } = useSelector((state) => state.auth);
  const { users, externalUsers, isError, message } = useSelector(
    (state) => state.users
  );

  let location = useLocation();

  useEffect(() => {
    if (!user) {
      const queryParams = { returnUrl: location.pathusername };
      const qString = queryString.stringify(queryParams);
      navigate(`/login?${qString}`);
    }
  }, [user, navigate]);

  useEffect(() => {
    if (isError && message === "Unauthorized") {
      dispatch(reset());
      dispatch(logout());
      return;
    }
    if (isError && message) {
      alert(message);
    }
  }, [isError, message, dispatch]);

  useEffect(() => {
    if (user && user.role === "SUPERADMIN") {
      setIsAdmin(user.role === "SUPERADMIN");
    } else if (user && user.role !== "SUPERADMIN" && user.role !== "ADMIN") {
      navigate("/");
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (user && selectedTabIndex === 0) dispatch(getUsers());
    if (user && selectedTabIndex === 1) dispatch(getExternalUsers());
  }, [user, dispatch, selectedTabIndex]);

  useEffect(() => {
    if (users.length > 0) {
      const q = userSearchQuery.toUpperCase();
      setFilteredUsers(
        users.filter(
          (user) =>
            user.username.toUpperCase().includes(q) ||
            user.email.toUpperCase().includes(q) ||
            (user.Customer && user.Customer.name.toUpperCase().includes(q))
        )
      );
    }
  }, [users, userSearchQuery]);

  useEffect(() => {
    if (externalUsers.length > 0) {
      const q = externalUserSearchQuery.toUpperCase();
      setFilteredExternalUsers(
        externalUsers
          .filter(
            (user) =>
              user.username.toUpperCase().includes(q) ||
              user.phone.includes(q) ||
              user.description.toUpperCase().includes(q)
          )
          .sort((a, b) =>
            a[externalUserSortBy].localeCompare(b[externalUserSortBy])
          )
      );
    } else {
      setFilteredExternalUsers([]);
    }
  }, [externalUsers, externalUserSearchQuery, externalUserSortBy]);

  const handleUserFormDataCHange = (e) => {
    setUserFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCustomerChange = (c) => {
    if (c.id)
      setUserFormData((prevState) => ({
        ...prevState,
        Customer: c,
      }));
  };

  const handleUserActiveChanged = (e) => {
    setUserFormData((prevState) => ({
      ...prevState,
      isActive: e.target.checked,
    }));
  };

  const handleExternalUserFormDataCHange = (e) => {
    setExternalUserFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleExternalUserActiveChanged = (e) => {
    setExternalUserFormData((prevState) => ({
      ...prevState,
      isActive: e.target.checked,
    }));
  };

  const handleAddUserClick = () => {
    setUserFormData(initialUserState);
    setUserFormDialogOpen(true);
  };

  const handleAddExternalUserClick = () => {
    setExternalUserFormData(initialExternalUserState);
    setExternalUserFormDialogOpen(true);
  };

  const handleEditUserClick = (user) => {
    setUserFormData(user);
    setUserFormDialogOpen(true);
  };

  const handleEditExternalUserClick = (user) => {
    if (user.phone.startsWith("08"))
      user = { ...user, phone: user.phone.substring(2) };

    setExternalUserFormData(user);
    setExternalUserFormDialogOpen(true);
  };

  const handleUserFormDialogClose = () => {
    setUserFormData(initialUserState);
    setUserFormDialogOpen(false);
  };

  const handleDeleteExternalUserClick = (user) => {
    setExternalUserFormData(user);
    setExternalUserDeleteConfirmationDialogOpen(true);
  };

  const handleExternalUserFormDialogClose = () => {
    setExternalUserFormData(initialExternalUserState);
    setExternalUserFormDialogOpen(false);
  };

  const handleExternalUserDeleteConfirmationDialogClose = () => {
    setExternalUserFormData(initialExternalUserState);
    setExternalUserDeleteConfirmationDialogOpen(false);
  };

  const handleSubmitUser = () => {
    const data = {
      id: userFormData.id ?? null,
      username: userFormData.username,
      email: userFormData.email,
      role: userFormData.role,
      customer_id: userFormData.Customer ? userFormData.Customer.id : null,
      isActive: userFormData.isActive,
    };

    console.log(data);

    if (userFormData.id) {
      dispatch(updateUser(data));
    } else {
      dispatch(createUser(data));
    }
    setUserFormDialogOpen(false);
  };

  const handleSubmitExternalUser = () => {
    let data = {
      username: externalUserFormData.username,
      phone: `08${externalUserFormData.phone}`,
      description: externalUserFormData.description,
      isActive: externalUserFormData.isActive,
    };

    if (externalUserFormData.id) {
      data = { ...data, id: externalUserFormData.id };
      dispatch(updateExternalUser(data));
    } else {
      dispatch(createExternalUser(data));
    }
    setExternalUserFormDialogOpen(false);
  };

  const handleDeleteExternalUser = () => {
    dispatch(deleteExternalUser({ id: externalUserFormData.id }));
    setExternalUserDeleteConfirmationDialogOpen(false);
    setExternalUserFormData(initialExternalUserState);
  };

  return (
    <div>
      <CssBaseline />
      <Navbar isAdmin={user.role === "SUPERADMIN" || user.role === "ADMIN"} />
      <Container maxWidth="lg">
        <Tabs
          value={selectedTabIndex}
          onChange={handleSelectedTabChange}
          sx={{ mt: 2 }}
        >
          <Tab label="Users" />
          <Tab label="External Users" />
        </Tabs>
        <CustomTabPanel value={selectedTabIndex} index={0}>
          <Stack direction="row" alignItems="center">
            <Typography variant="h5" sx={{ my: 2, mr: 2 }}>
              Users
            </Typography>
            {isAdmin && (
              <Button
                size="small"
                variant="outlined"
                endIcon={<Add />}
                onClick={handleAddUserClick}
              >
                Add
              </Button>
            )}
            <TextField
              size="small"
              label="Search..."
              value={userSearchQuery}
              onChange={(e) => setUserSearchQuery(e.target.value)}
              variant="outlined"
              sx={{ ml: 2 }}
            />
          </Stack>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Role</TableCell>
                  <TableCell align="left">Customer</TableCell>
                  <TableCell align="left">Active</TableCell>
                  {isAdmin && <TableCell align="right"></TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {users &&
                  filteredUsers.map((row) => (
                    <TableRow
                      key={row.username}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.username}
                      </TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.role}</TableCell>
                      <TableCell align="left">
                        {row.Customer ? row.Customer.name : ""}
                      </TableCell>
                      <TableCell align="left">
                        {row.isActive ? "Yes" : "No"}
                      </TableCell>
                      {isAdmin && (
                        <TableCell align="right">
                          <IconButton onClick={() => handleEditUserClick(row)}>
                            <Edit />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog open={userFormDialogOpen} onClose={handleUserFormDialogClose}>
            <DialogTitle>
              {userFormData.id === null ? "Add New User" : "Update User"}
            </DialogTitle>
            <DialogContent>
              <TextField
                value={userFormData.username}
                label="Username"
                name="username"
                onChange={handleUserFormDataCHange}
                size="small"
                fullWidth
              />
              <TextField
                value={userFormData.email}
                label="Email"
                name="email"
                type="email"
                onChange={handleUserFormDataCHange}
                size="small"
                fullWidth
                sx={{ mt: 2 }}
                disabled={userFormData.id !== null}
              />
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel size="small">Role</InputLabel>
                <Select
                  value={userFormData.role}
                  label="Role"
                  name="role"
                  size="small"
                  onChange={handleUserFormDataCHange}
                >
                  <MenuItem value="SUPERADMIN">SUPERADMIN</MenuItem>
                  <MenuItem value="ADMIN">ADMIN</MenuItem>
                  <MenuItem value="EXTERNAL">EXTERNAL</MenuItem>
                </Select>
              </FormControl>
              <CustomerTextBox
                value={userFormData.Customer}
                setValue={handleCustomerChange}
                error={false}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={userFormData.isActive}
                    onChange={handleUserActiveChanged}
                  />
                }
                label="Active"
              />
            </DialogContent>
            <DialogActions>
              <Button color="inherit" onClick={handleUserFormDialogClose}>
                CANCEL
              </Button>
              <Button onClick={handleSubmitUser}>SUBMIT</Button>
            </DialogActions>
          </Dialog>
        </CustomTabPanel>

        <CustomTabPanel value={selectedTabIndex} index={1}>
          <Grid container spacing={2} alignItems="center" sx={{ mt: 1, mb: 3 }}>
            <Grid item>
              <Stack direction="row" alignItems="center">
                <Typography variant="h5" sx={{ mr: 2 }}>
                  External Users
                </Typography>
                {isAdmin && (
                  <Button
                    size="small"
                    variant="outlined"
                    endIcon={<Add />}
                    onClick={handleAddExternalUserClick}
                  >
                    Add
                  </Button>
                )}
              </Stack>
            </Grid>
            <Grid item>
              <TextField
                size="small"
                label="Search..."
                variant="outlined"
                value={externalUserSearchQuery}
                onChange={(e) => setExternalUserSearchQuery(e.target.value)}
              />
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <InputLabel>Sort</InputLabel>
                <Select
                  size="small"
                  label="Sort by"
                  value={externalUserSortBy}
                  onChange={(e) => setExternalUserSortBy(e.target.value)}
                >
                  <MenuItem value="username">Username</MenuItem>
                  <MenuItem value="phone">Phone</MenuItem>
                  <MenuItem value="description">Description</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell align="left">Phone</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Active</TableCell>
                  {isAdmin && <TableCell align="right"></TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {externalUsers &&
                  filteredExternalUsers.map((row) => (
                    <TableRow
                      key={row.username}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.username}
                      </TableCell>
                      <TableCell align="left">{row.phone}</TableCell>
                      <TableCell align="left">{row.description}</TableCell>
                      <TableCell align="left">
                        {row.isActive ? "Yes" : "No"}
                      </TableCell>
                      {isAdmin && (
                        <TableCell align="right">
                          <IconButton
                            onClick={() => handleEditExternalUserClick(row)}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteExternalUserClick(row)}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog
            open={externalUserFormDialogOpen}
            onClose={handleExternalUserFormDialogClose}
          >
            <DialogTitle>
              {externalUserFormData.id === null
                ? "Add New External User"
                : "Update External User"}
            </DialogTitle>
            <DialogContent>
              <TextField
                value={externalUserFormData.username}
                label="Username"
                name="username"
                onChange={handleExternalUserFormDataCHange}
                size="small"
                fullWidth
              />
              <TextField
                value={externalUserFormData.phone}
                label="phone"
                name="phone"
                onChange={handleExternalUserFormDataCHange}
                size="small"
                sx={{ mt: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">08</InputAdornment>
                  ),
                }}
                fullWidth
              />
              <TextField
                value={externalUserFormData.description}
                label="Description"
                name="description"
                onChange={handleExternalUserFormDataCHange}
                size="small"
                sx={{ mt: 2 }}
                fullWidth
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={externalUserFormData.isActive}
                    onChange={handleExternalUserActiveChanged}
                  />
                }
                label="Active"
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="inherit"
                onClick={handleExternalUserFormDialogClose}
              >
                CANCEL
              </Button>
              <Button onClick={handleSubmitExternalUser}>SUBMIT</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={externalUserDeleteConfirmationDialogOpen}
            onClose={handleExternalUserDeleteConfirmationDialogClose}
          >
            <DialogTitle>Confirm Delete External User</DialogTitle>
            <DialogContent>
              {`Are you sure you want to delete ${externalUserFormData.username} (${externalUserFormData.description})`}
            </DialogContent>
            <DialogActions>
              <Button
                color="inherit"
                onClick={handleExternalUserDeleteConfirmationDialogClose}
              >
                CANCEL
              </Button>
              <Button color="error" onClick={handleDeleteExternalUser}>
                DELETE
              </Button>
            </DialogActions>
          </Dialog>
        </CustomTabPanel>
      </Container>
    </div>
  );
};

export default Users;
