import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addShipmentItem,
  updateShipmentItem,
  deleteShipmentItem,
} from "../features/shipments/shipmentSlice";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TextField,
  Stack,
  TableContainer,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { NumericFormat } from "react-number-format";

const initialFormDataState = {
  shipment_id: null,
  item_no: "",
  ref_no: "",
  name: "",
  qty: "",
  unit: "",
  expired: "",
  batch_no: "",
};

function ItemTable(props) {
  const { items, shipment, package_id, isEditable } = props;
  const dispatch = useDispatch();
  const [newRowVisible, setNewRowVisible] = useState(false);
  const [editableRowId, setEditableRowId] = useState(null);
  const [formData, setFormData] = useState(initialFormDataState);
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (editableRowId) {
      setFormData(items.filter((e) => e.id === editableRowId)[0]);
      setNewRowVisible(false);
    } else {
      setFormData(initialFormDataState);
    }
  }, [editableRowId, items]);

  const handleNewRowClick = () => {
    setNewRowVisible(true);
    setEditableRowId(null);
  };

  const handleFormDataChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDeleteItem = (row) => {
    dispatch(deleteShipmentItem(row));
  };

  const handleSubmitFormData = (e) => {
    e.preventDefault();

    if (formData.id) {
      dispatch(updateShipmentItem(formData));
      setEditableRowId(null);
    } else {
      dispatch(
        addShipmentItem({
          shipment_id: shipment.id,
          package_id: package_id,
          item_no: formData.item_no,
          ref_no: formData.ref_no,
          name: formData.name,
          qty: formData.qty,
          unit: formData.unit,
          batch_no: formData.batch_no,
          expired: formData.expired,
        })
      );
      // setNewRowVisible(false);
      setEditableRowId(null);
      e.target.blur();
    }
  };
  return (
    <div>
      <Stack direction="row" alignItems="center">
        {smallscreen && !newRowVisible && (
          <IconButton
            aria-label="Add Shipment Item"
            onClick={() => handleNewRowClick()}
          >
            <AddIcon />
          </IconButton>
        )}
      </Stack>
      <TableContainer>
        <Table
          aria-label="tracking-table"
          size="small"
          sx={(theme) => ({
            backgroundColor: theme.palette.background.default,
          })}
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: 50 }}>No.</TableCell>
              <TableCell sx={{ minWidth: 100 }}>No. Ref</TableCell>
              <TableCell sx={{ minWidth: 100 }}>Nama Barang</TableCell>
              <TableCell sx={{ minWidth: 100 }}>Qty</TableCell>
              <TableCell sx={{ minWidth: 100 }}>Satuan</TableCell>
              <TableCell sx={{ minWidth: 100 }}>No. Batch</TableCell>
              <TableCell sx={{ minWidth: 100 }}>Expired</TableCell>
              {isEditable && (
                <TableCell align="right">
                  {!smallscreen && !newRowVisible && (
                    <IconButton
                      aria-label="Add Shipment Item"
                      onClick={() => handleNewRowClick()}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell sx={{ minWidth: 50 }} component="th" scope="row">
                  {row.id === editableRowId ? (
                    <TextField
                      size="small"
                      fullWidth
                      autoFocus
                      variant="standard"
                      name="item_no"
                      value={formData.item_no}
                      onChange={handleFormDataChange}
                    />
                  ) : (
                    <Typography variant="body2">{row.item_no}</Typography>
                  )}
                </TableCell>
                <TableCell sx={{ minWidth: 100 }} component="th" scope="row">
                  {row.id === editableRowId ? (
                    <TextField
                      size="small"
                      fullWidth
                      variant="standard"
                      name="ref_no"
                      value={formData.ref_no}
                      onChange={handleFormDataChange}
                    />
                  ) : (
                    <Typography variant="body2">{row.ref_no}</Typography>
                  )}
                </TableCell>
                <TableCell sx={{ minWidth: 100 }} component="th" scope="row">
                  {row.id === editableRowId ? (
                    <TextField
                      size="small"
                      fullWidth
                      variant="standard"
                      name="name"
                      value={formData.name}
                      onChange={handleFormDataChange}
                    />
                  ) : (
                    <Typography variant="body2">{row.name}</Typography>
                  )}
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>
                  {row.id === editableRowId ? (
                    <NumericFormat
                      customInput={TextField}
                      size="small"
                      fullWidth
                      decimalSeparator=","
                      thousandSeparator="."
                      valueIsNumericString={false}
                      variant="standard"
                      name="qty"
                      value={formData.qty}
                      onValueChange={(values, sourceInfo) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          qty: values.floatValue,
                        }));
                      }}
                    />
                  ) : (
                    <Typography variant="body2">
                      <NumericFormat
                        displayType="text"
                        decimalSeparator=","
                        valueIsNumericString={false}
                        thousandSeparator="."
                        value={row.qty}
                      />
                    </Typography>
                  )}
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>
                  {row.id === editableRowId ? (
                    <TextField
                      size="small"
                      fullWidth
                      variant="standard"
                      name="unit"
                      value={formData.unit}
                      onChange={handleFormDataChange}
                    />
                  ) : (
                    <Typography variant="body2">{row.unit}</Typography>
                  )}
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>
                  {row.id === editableRowId ? (
                    <TextField
                      size="small"
                      fullWidth
                      variant="standard"
                      name="batch_no"
                      value={formData.batch_no}
                      onChange={handleFormDataChange}
                    />
                  ) : (
                    <Typography variant="body2">{row.batch_no}</Typography>
                  )}
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>
                  {row.id === editableRowId ? (
                    <TextField
                      size="small"
                      fullWidth
                      variant="standard"
                      name="expired"
                      value={formData.expired}
                      onChange={handleFormDataChange}
                    />
                  ) : (
                    <Typography variant="body2">{row.expired}</Typography>
                  )}
                </TableCell>
                {isEditable && (
                  <TableCell align="right">
                    {row.id === editableRowId ? (
                      <Stack direction="row" justifyContent="flex-end">
                        <IconButton
                          aria-label="submit"
                          onClick={handleSubmitFormData}
                        >
                          <CheckIcon />
                        </IconButton>
                        <IconButton
                          aria-label="cancel"
                          onClick={() => setEditableRowId(null)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Stack>
                    ) : (
                      <Stack direction="row" justifyContent="flex-end">
                        <IconButton
                          aria-label="Edit"
                          onClick={() => setEditableRowId(row.id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="Delete"
                          onClick={() => handleDeleteItem(row)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
            {newRowVisible && (
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  <TextField
                    size="small"
                    fullWidth
                    autoFocus
                    variant="standard"
                    name="item_no"
                    value={formData.item_no}
                    onChange={handleFormDataChange}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <TextField
                    size="small"
                    fullWidth
                    variant="standard"
                    name="ref_no"
                    value={formData.ref_no}
                    onChange={handleFormDataChange}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <TextField
                    size="small"
                    fullWidth
                    variant="standard"
                    name="name"
                    value={formData.name}
                    onChange={handleFormDataChange}
                  />
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>
                  <NumericFormat
                    customInput={TextField}
                    size="small"
                    fullWidth
                    decimalSeparator=","
                    thousandSeparator="."
                    valueIsNumericString={false}
                    variant="standard"
                    name="qty"
                    value={formData.qty}
                    onValueChange={(values, sourceInfo) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        qty: values.floatValue,
                      }));
                    }}
                  />
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>
                  <TextField
                    size="small"
                    fullWidth
                    variant="standard"
                    name="unit"
                    value={formData.unit}
                    onChange={handleFormDataChange}
                  />
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>
                  <TextField
                    size="small"
                    fullWidth
                    variant="standard"
                    name="batch_no"
                    value={formData.batch_no}
                    onChange={handleFormDataChange}
                  />
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>
                  <TextField
                    size="small"
                    fullWidth
                    variant="standard"
                    name="expired"
                    value={formData.expired}
                    onChange={handleFormDataChange}
                  />
                </TableCell>
                {isEditable && (
                  <TableCell align="right">
                    <Stack direction="row" justifyContent="flex-end">
                      <IconButton
                        aria-label="submit"
                        onClick={handleSubmitFormData}
                      >
                        <CheckIcon />
                      </IconButton>
                      <IconButton
                        aria-label="cancel"
                        onClick={() => setNewRowVisible(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Stack>
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ItemTable;
