import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  createShipment,
  getShipmentAddress,
} from "../features/shipments/shipmentSlice";
import ShipmentService from "../features/shipments/shipmentService";
import CustomerTextBox from "./CustomerTextBox";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import * as uuid from "uuid";
import {
  DialogContentText,
  Typography,
  IconButton,
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Autocomplete,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  debounce,
  LinearProgress,
} from "@mui/material";
// import ShipmentItemsTable from "./ShipmentItemsTable";
import TrackingList from "./TrackingList";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
// import { NumericFormat } from "react-number-format";
import queryString from "query-string";
import { Place } from "@mui/icons-material";

const shippingLineOptions = [
  "Export Air",
  "Export Air 2",
  "Export Sea",
  "Import Air",
  "Import Air 2",
  "Import Sea",
  "Domestic Air",
  "Domestic Air 2",
  "Domestic Land",
  "Domestic Sea",
];

// const itemUnitOptions = ["Set / Piece", "P", "C", "Box"];

const initialShipmentDataState = (folder) => {
  const session_id = uuid.v4();
  return {
    folder: folder,
    customer_id: -1,
    customer: null,
    from: "",
    to: "",
    line: "",
    commodity: "",
    totalItem: "",
    totalWeight: "",
    itemValue: "",
    session_id: session_id,
    ShipmentTrackings: [],
    ShipmentItems: [],
  };
};

const iniitialNewTrackingState = {
  type: "",
  number: "",
};

const AddressListDialog = (props) => {
  const { open, onChange, onClose } = props;
  const [keyword, setKeyword] = useState("");
  const dispatch = useDispatch();

  const { addresses, isLoading, isError, message } = useSelector(
    (state) => state.shipments
  );

  const fetchData = (query) => {
    const params = { q: query };
    dispatch(getShipmentAddress(params));
  };

  const debounced = useCallback(debounce(fetchData, 500), []);

  const handleKeywordChange = async (e) => {
    setKeyword(e.target.value);

    debounced(e.target.value, 500);
  };

  const handleSelectAddress = (address) => {
    onChange(address);
    setKeyword("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth keepMounted={false}>
      <DialogTitle>Address List</DialogTitle>
      <DialogContent>
        {isLoading && <LinearProgress />}

        <TextField
          name="search"
          label="Find Address"
          placeholder="search..."
          value={keyword}
          onChange={handleKeywordChange}
          fullWidth
          autoFocus
        />

        <List component="nav" aria-label="main mailbox folders">
          {addresses &&
            addresses.map((address, i) => {
              const parts = address.split("\n");
              const primary = parts[0];
              const secondary = parts.length > 1 ? parts[1] : "";
              return (
                <ListItemButton
                  key={i}
                  onClick={() => handleSelectAddress(address)}
                >
                  <ListItemIcon>
                    <Place />
                  </ListItemIcon>
                  <ListItemText
                    primary={primary}
                    secondary={address.substring(primary.length)}
                  />
                </ListItemButton>
              );
            })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ShipmentFormDialog = (props) => {
  const { open, onClose } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeFolder = searchParams.get("activeFolder") ?? "";

  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [shipmentData, setShipmentData] = useState(
    initialShipmentDataState(activeFolder)
  );
  const [newTracking, setNewTracking] = useState(iniitialNewTrackingState);
  // const [newItem, setNewItem] = useState(initialNewItemState);
  const [submitted, setSubmitted] = useState(false);
  const [emptyValidation, setemptyValidation] = useState(false);
  const [duplicateConfirmationDialog, setDuplicateConfirmationDialog] =
    useState(false);
  const [trackingLoading, setTrackingLoading] = useState(false);
  const [addressListDialogOpen1, setAddressListDialogOpen1] = useState(false);
  const [addressListDialogOpen2, setAddressListDialogOpen2] = useState(false);

  const { isLoading, isError, message } = useSelector(
    (state) => state.shipments
  );

  const handleFormChange = (e) => {
    setShipmentData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleShipmentDataChange = (name, value) => {
    setShipmentData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCustomerChange = (newValue) => {
    setShipmentData((prevState) => ({
      ...prevState,
      customer: newValue,
      customer_id: newValue && newValue.id,
    }));
  };

  const handleNewTrackingChange = (e) => {
    setNewTracking((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDuplicateConfirmationDialogClose = () => {
    setDuplicateConfirmationDialog(false);
  };

  const handleAddNewTracking = async () => {
    setTrackingLoading(true);
    const trackingNo = await ShipmentService.checkShipmentTracking(
      newTracking.number.trim()
    );

    if (trackingNo.exists) {
      setDuplicateConfirmationDialog(true);
    } else {
      addNewTracking();
    }
    setTrackingLoading(false);
  };

  const addNewTracking = () => {
    setShipmentData((prevState) => ({
      ...prevState,
      ShipmentTrackings: [...prevState.ShipmentTrackings, newTracking],
    }));
    setDuplicateConfirmationDialog(false);
  };

  const handleDeleteTracking = (index) => {
    let temp = [...shipmentData.ShipmentTrackings];
    temp.splice(index, 1);
    setShipmentData((prevState) => ({
      ...prevState,
      ShipmentTrackings: temp,
    }));
  };

  const handleViewDataTracking = (e) => {
    const queryParams = { q: newTracking.number.trim() };
    const qString = queryString.stringify(queryParams);
    navigate(`/?${qString}`);
    setDuplicateConfirmationDialog(false);
    handleClose(e);
  };

  const handleAddressChange = (address, fieldName) => {
    setShipmentData((prevState) => ({
      ...prevState,
      [fieldName]: address,
    }));
  };

  const handleClose = (e) => {
    setShipmentData(initialShipmentDataState(activeFolder));
    // setNewItem(initialNewItemState);
    setNewTracking(iniitialNewTrackingState);
    onClose(e);
  };

  const validateEmptyFields = () => {
    if (shipmentData.customer_id < 0) return true;
    if (
      !shipmentData.from ||
      shipmentData.from === null ||
      shipmentData.from.trim() === ""
    )
      return true;
    if (
      !shipmentData.to ||
      shipmentData.to === null ||
      shipmentData.to.trim() === ""
    )
      return true;
    if (
      !shipmentData.line ||
      shipmentData.line === null ||
      shipmentData.line.trim() === ""
    )
      return true;
    return false;
  };

  const isEmptyField = (value) => {
    if (typeof value === "string")
      return !value || value === null || value.trim() === "";
    else if (typeof value === "number")
      return !value || value === null || value < 0;
    else return !value || value === null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateEmptyFields()) {
      setemptyValidation(true);
      return;
    }

    dispatch(createShipment(shipmentData));
    setSubmitted(true);
  };

  useEffect(() => {
    if (submitted && !isError && !isLoading) {
      setShipmentData(initialShipmentDataState(activeFolder));
      // setNewItem(initialNewItemState);
      setNewTracking(iniitialNewTrackingState);
      setSubmitted(false);
      navigate(0);
    }
  }, [submitted, isLoading, isError, message, activeFolder, navigate]);

  useEffect(() => {
    setShipmentData((prevState) => ({ ...prevState, folder: activeFolder }));
  }, [activeFolder]);

  return (
    <div>
      <Dialog
        fullScreen={smallscreen}
        keepMounted={false}
        fullWidth
        maxWidth="md"
        open={open}
      >
        <DialogTitle variant="h4">
          New Shipment
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={8} md={6}>
              <Typography variant="h5">Folder</Typography>
              <Typography variant="body2" color="GrayText">
                /{shipmentData.folder}
              </Typography>
              <Typography variant="h5" sx={{ mt: 2.5 }}>
                Shipment
              </Typography>
              <CustomerTextBox
                name="customer"
                value={shipmentData.customer}
                setValue={handleCustomerChange}
                error={
                  emptyValidation && isEmptyField(shipmentData.customer_id)
                }
              />
              <TextField
                error={emptyValidation && isEmptyField(shipmentData.from)}
                label="From"
                placeholder="Company name / City / Address..."
                multiline
                minRows={3}
                fullWidth
                maxRows={4}
                size="small"
                sx={{ mt: 2 }}
                name="from"
                value={shipmentData.from}
                onChange={handleFormChange}
              />
              <Typography
                color="inherit"
                variant="caption"
                sx={{ m: 1, cursor: "pointer" }}
                onClick={() => setAddressListDialogOpen1(true)}
              >
                ADDRESS LIST
              </Typography>
              <AddressListDialog
                open={addressListDialogOpen1}
                onClose={() => setAddressListDialogOpen1(false)}
                onChange={(address) => handleAddressChange(address, "from")}
              />
              <TextField
                error={emptyValidation && isEmptyField(shipmentData.to)}
                label="To"
                placeholder="Company name / City / Address..."
                multiline
                minRows={3}
                fullWidth
                maxRows={4}
                size="small"
                sx={{ mt: 2 }}
                name="to"
                value={shipmentData.to}
                onChange={handleFormChange}
              />
              <Typography
                color="inherit"
                variant="caption"
                sx={{ m: 1, cursor: "pointer" }}
                onClick={() => setAddressListDialogOpen2(true)}
              >
                ADDRESS LIST
              </Typography>
              <AddressListDialog
                open={addressListDialogOpen2}
                onClose={() => setAddressListDialogOpen2(false)}
                onChange={(address) => handleAddressChange(address, "to")}
              />
              <Autocomplete
                freeSolo
                disableClearable
                size="small"
                value={shipmentData.line}
                onInputChange={(e, newInputValue) =>
                  handleShipmentDataChange("line", newInputValue)
                }
                options={shippingLineOptions}
                sx={{ mt: 2 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={emptyValidation && isEmptyField(shipmentData.line)}
                    label="Transportation Type"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
              <TextField
                error={emptyValidation && isEmptyField(shipmentData.commodity)}
                label="Commodity"
                placeholder="Commodity..."
                fullWidth
                size="small"
                sx={{ mt: 2 }}
                name="commodity"
                value={shipmentData.commodity}
                onChange={handleFormChange}
              />
              <hr style={{ marginTop: "1em" }} />
            </Grid>
            <Grid item xs={12} sm={8} md={6}>
              <Typography variant="h5">Tracking</Typography>
              <TextField
                label="Type"
                placeholder="SBBK, SMU, MAWB, etc.."
                size="small"
                name="type"
                sx={{ mt: 1 }}
                value={newTracking.type}
                onChange={handleNewTrackingChange}
              />
              <TextField
                label="Number"
                fullWidth
                placeholder="Tracking or Document Number...  "
                size="small"
                name="number"
                sx={{ mt: 2 }}
                value={newTracking.number}
                onChange={handleNewTrackingChange}
              />
              <Button
                variant="outlined"
                fullWidth
                size="medium"
                sx={{ mt: 2 }}
                disabled={trackingLoading}
                onClick={handleAddNewTracking}
              >
                ADD
              </Button>
              <TrackingList
                items={shipmentData.ShipmentTrackings}
                onDelete={handleDeleteTracking}
              />
              <Dialog
                open={duplicateConfirmationDialog}
                onClose={handleDuplicateConfirmationDialogClose}
                aria-labelledby="tracking-number-duplicate-confirmation"
                aria-describedby="tracking-number-duplicate-confirmation"
              >
                <DialogTitle>{"Duplicate Tracking Number"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Nomor tracking: {newTracking.type} - {newTracking.number}{" "}
                    sudah pernah di-input sebelumnya.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button color="inherit" onClick={handleViewDataTracking}>
                    Lihat Data
                  </Button>
                  <Button
                    color="inherit"
                    onClick={handleDuplicateConfirmationDialogClose}
                  >
                    Batalkan
                  </Button>
                  <Button onClick={addNewTracking} autoFocus>
                    Lanjutkan
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
          {message}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button
            size="large"
            onClick={handleClose}
            color="secondary"
            tabIndex={-1}
            sx={{ mx: 3, my: 1 }}
          >
            Cancel
          </Button>
          <LoadingButton
            size="large"
            variant="contained"
            loading={isLoading}
            onClick={handleSubmit}
            sx={{ mx: 3, my: 1 }}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ShipmentFormDialog;
