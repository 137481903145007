import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getCustomers,
  createCustomer,
  reset,
} from "../features/customers/customerSlice";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions();

const CustomerTextBox = (props) => {
  const { value, setValue, error } = props;
  const [newCustomerDialogOpem, setNewCustomerDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { customers, customer, isError, message } = useSelector(
    (state) => state.customers
  );

  useEffect(() => {
    if (isError) {
      console.log(message);
    }

    if (!user) {
      navigate("/login");
    } else {
      dispatch(getCustomers());
    }

    return () => {
      dispatch(reset());
    };
  }, [user, isError, dispatch, message, navigate]);

  useEffect(() => {
    if (customer && customer.id) {
      setValue(customer);
    }
  }, [customer]);

  const handleAddCustomerDialogClose = () => {
    setNewCustomerName("");
    setNewCustomerDialogOpen(false);
  };

  const [newCustomerName, setNewCustomerName] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(createCustomer({ name: newCustomerName }));

    handleAddCustomerDialogClose();
  };

  return (
    <>
      <Autocomplete
        fullWidth
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              setNewCustomerDialogOpen(true);
              setNewCustomerName(newValue);
            });
          } else if (newValue && newValue.inputValue) {
            setNewCustomerDialogOpen(true);
            setNewCustomerName(newValue.inputValue);
          } else {
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="customer-text-box"
        sx={{ mt: 2 }}
        options={customers}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name || "";
        }}
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} error={error} size="small" label="Customer" />
        )}
      />
      <Dialog
        open={newCustomerDialogOpem}
        onClose={handleAddCustomerDialogClose}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new Customer</DialogTitle>
          <DialogContent>
            <DialogContentText>
              New Customer must be added to database first before submitting new
              Shipment
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              fullWidth
              value={newCustomerName}
              onChange={(event) => setNewCustomerName(event.target.value)}
              label="Customer's Name"
              type="text"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddCustomerDialogClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CustomerTextBox;
