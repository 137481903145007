import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Container } from "@mui/system";
import {
  Button,
  Dialog,
  DialogTitle,
  Typography,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TrackingStatusFormDialog from "./TrackingStatusFormDialog";
import TrackingStatusTimeline from "./TrackingStatusTimeline";
import ShipmentStatusChip from "./ShipmentStatusChip";

export default function TrackingStatusDialog(props) {
  const { shipment, onClose, open, isAdmin } = props;
  const [updateStatusFormDialogOpen, setUpdateStatusFormDialogOpen] =
    useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isEditable = isAdmin && !shipment.isClosed;

  const handleUpdateStatusFormDialogOpen = () => {
    setUpdateStatusFormDialogOpen(true);
  };

  const handleUpdateStatusFormDialogClose = () => {
    setUpdateStatusFormDialogOpen(false);
  };

  const handleShipmentStatusReload = () => {
    setUpdateStatusFormDialogOpen(false);
  };

  return (
    <Dialog onClose={onClose} open={open} fullScreen={fullScreen}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Status Pengiriman
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 15,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Container>
          <Stack direction="row" spacing={1}>
            <Stack direction="row">
              <Typography variant="h6" sx={{ mb: 1 }}>
                {shipment.shipmentNo || shipment.session_id}
              </Typography>
              {shipment.latestStatus && (
                <ShipmentStatusChip
                  status={shipment.latestStatus.status}
                  variant="filled"
                  color={shipment.latestStatus.type}
                  size="medium"
                  sx={{ mx: 1, mb: 2 }}
                />
              )}
            </Stack>
          </Stack>
        </Container>
        <TrackingStatusTimeline
          statuses={shipment.ShipmentStatuses}
          shipment={shipment}
          isEditable={isEditable}
        />
        <TrackingStatusFormDialog
          shipment={shipment}
          open={updateStatusFormDialogOpen}
          onClose={handleUpdateStatusFormDialogClose}
          onUpdate={handleShipmentStatusReload}
        />
      </DialogContent>
      {isEditable && (
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button onClick={handleUpdateStatusFormDialogOpen}>Update</Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
