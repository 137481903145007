import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createTempShipmentFolder,
  updateShipmentFolder,
} from "../features/shipments/shipmentSlice";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Button,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  ListItem,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import MoreVertIcon from "@mui/icons-material/MoreVert";

function newToOld(a, b) {
  return a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0;
}

function oldToNew(a, b) {
  return a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0;
}

function alphaAsc(a, b) {
  return a.folder < b.folder ? -1 : a.folder > b.folder ? 1 : 0;
}

function alphaDesc(a, b) {
  return a.folder < b.folder ? 1 : a.folder > b.folder ? -1 : 0;
}

const getFilteredFolders = (folders, activeFolder, activeFoldersLvl, sort) => {
  if (folders && folders.length > 0) {
    let f = folders.filter(
      (e) =>
        e.folder.startsWith(activeFolder ?? "") && e.folder !== activeFolder
    );

    let sortFunction = alphaAsc;

    switch (sort) {
      case "asc":
      default:
        break;
      case "desc":
        sortFunction = alphaDesc;
        break;
      case "old":
        sortFunction = oldToNew;
        break;
      case "new":
        sortFunction = newToOld;
    }

    f = f.sort(sortFunction);

    f = f.map((e) => e.folder.split("/")[activeFoldersLvl] + "/");
    f = [...new Set(f)];
    return f;
  }
  return [];
};

const CategoryItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "left",
  color: theme.palette.text.primary,
  height: "100%",
}));

const ShipmentCategoryList = (props) => {
  const { folders, activeFolder, onActiveFolderChanged, isAdmin, fullWidth } =
    props;
  const [filteredFolders, setFilteredFolders] = useState([]);
  const [sortFolder, setSortFolder] = useState("asc");
  const [activeFoldersLvl, setActiveFoldersLvl] = useState(0);
  const [folderMenuAnchorEl, setFolderMenuAnchorEl] = useState(null);
  const [createFolderFormDialogOpen, setCreateFolderFormDialogOpen] =
    useState(false);
  const [editFolderFormDialogOpen, setEditFolderFormDialogOpen] =
    useState(false);
  const [oldFolderName, setOldFolderName] = useState(null);
  const [newFolderName, setNewFodlerName] = useState("");
  const [newFolderError, setNewFolderError] = useState(null);

  const folderMenuOpen = Boolean(folderMenuAnchorEl);
  const dispatch = useDispatch();

  const handleSelectedFoldersChanged = (value) => {
    onActiveFolderChanged(activeFolder ? activeFolder + value : value);
  };

  const handleSortFolderCHanged = (e) => {
    setSortFolder(e.target.value);
  };

  const handleFolderMenuOpen = (e, oldName) => {
    setFolderMenuAnchorEl(e.currentTarget);
    setNewFodlerName(oldName.replace(/\/+$/, ""));

    setOldFolderName(oldName);
  };

  const handleFolderMenuClose = () => {
    setFolderMenuAnchorEl(null);
  };

  const handleSelectedFolderClick = (index) => {
    let arr = activeFolder.split("/");
    arr = arr.slice(0, index + 1);

    onActiveFolderChanged(arr.join("/") + "/");
  };

  const handleUpFolder = () => {
    let arr = activeFolder.split("/");
    if (activeFoldersLvl > 1) {
      arr = arr.slice(0, arr.length - 2);
      onActiveFolderChanged(arr.join("/") + "/");
    } else {
      onActiveFolderChanged(null);
    }
  };

  const handleEditFolderClick = () => {
    setEditFolderFormDialogOpen(true);
    setFolderMenuAnchorEl(null);
  };

  const handleCreateFolderSubmit = (e) => {
    e.preventDefault();
    if (newFolderName.indexOf("/") > -1) {
      setNewFolderError("Folder name can't contain '/' character");
      return;
    } else {
      setNewFolderError(null);
    }

    dispatch(
      createTempShipmentFolder(`${activeFolder}${newFolderName}`.toUpperCase())
    );

    setNewFodlerName("");
    setCreateFolderFormDialogOpen(false);
  };

  const handleEditFolderNameSave = (e) => {
    e.preventDefault();
    if (newFolderName.indexOf("/") > -1) {
      setNewFolderError("Folder name can't contain '/' character");
      return;
    } else {
      setNewFolderError(null);
    }

    dispatch(
      updateShipmentFolder({
        oldName: activeFolder + oldFolderName,
        newName: activeFolder + newFolderName,
      })
    );

    setNewFodlerName("");
    setEditFolderFormDialogOpen(false);
  };

  useEffect(() => {
    const lvl = activeFolder ? activeFolder.split("/").length - 1 : 0;

    setActiveFoldersLvl(lvl);
  }, [activeFolder]);

  useEffect(() => {
    setFilteredFolders(
      getFilteredFolders(folders, activeFolder, activeFoldersLvl, sortFolder)
    );
  }, [folders, activeFolder, activeFoldersLvl, sortFolder]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        direction="row"
        spacing={{ xs: 1, md: 2 }}
        justifyContent="left"
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item>
              <Typography variant="h6">Folder</Typography>
            </Grid>
            {activeFoldersLvl > 0 && (
              <Grid item>
                <IconButton aria-label="Up" onClick={handleUpFolder}>
                  <ArrowUpwardIcon />
                </IconButton>
              </Grid>
            )}

            {activeFolder &&
              activeFolder.split("/").map((selectedFolder, i) => {
                return (
                  selectedFolder !== "" && (
                    <Grid item key={i}>
                      {i > 0 && "/"}
                      <Button
                        key={i}
                        onClick={() => handleSelectedFolderClick(i)}
                      >
                        {selectedFolder}
                      </Button>
                    </Grid>
                  )
                );
              })}
            {isAdmin && (
              <Grid item>
                {activeFolder && "/"}
                <Tooltip title="create new folder">
                  <IconButton
                    size="small"
                    aria-label="create-new-folder"
                    sx={{
                      ml: 0.5,
                      mt: -0.5,
                      color: (theme) => theme.palette.grey[700],
                    }}
                    onClick={() => {
                      setNewFodlerName("");
                      setCreateFolderFormDialogOpen(true);
                    }}
                  >
                    <CreateNewFolderIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {filteredFolders && filteredFolders.length > 0 && (
              <Grid item>
                <FormControl size="small" sx={{ ml: 2, width: 120 }}>
                  <InputLabel>Sort</InputLabel>
                  <Select
                    label="Sort"
                    value={sortFolder}
                    onChange={handleSortFolderCHanged}
                  >
                    <MenuItem value="asc">A-Z</MenuItem>
                    <MenuItem value="desc">Z-A</MenuItem>
                    <MenuItem value="old">Oldest</MenuItem>
                    <MenuItem value="new">Newest</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Grid>
        {filteredFolders
          ? filteredFolders.map((folder, i) => (
              <Grid
                item
                key={i}
                xs={12}
                sm={fullWidth ? 12 : 6}
                md={fullWidth ? 12 : 4}
                lg={fullWidth ? 12 : 3}
              >
                <CategoryItem elevation={1}>
                  <ListItem
                    disablePadding
                    sx={{ height: "100%" }}
                    alignItems="center"
                    secondaryAction={
                      isAdmin && (
                        <IconButton
                          edge="end"
                          onClick={(e) => handleFolderMenuOpen(e, folder)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )
                    }
                  >
                    <ListItemButton
                      sx={{ height: "100%" }}
                      onClick={() => handleSelectedFoldersChanged(folder)}
                    >
                      <ListItemIcon>
                        <FolderIcon />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography noWrap variant="body2">
                          {folder.replace(/\/+$/, "")}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </CategoryItem>
              </Grid>
            ))
          : null}
        <Menu
          anchorEl={folderMenuAnchorEl}
          open={folderMenuOpen}
          onClose={handleFolderMenuClose}
        >
          <MenuItem onClick={() => handleEditFolderClick()}>Rename</MenuItem>
        </Menu>
      </Grid>
      <Dialog
        open={createFolderFormDialogOpen}
        onClose={() => setCreateFolderFormDialogOpen(false)}
      >
        <DialogTitle>Create New Folder</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This folder will become temporary.
          </DialogContentText>
          <form onSubmit={handleCreateFolderSubmit}>
            <TextField
              autoFocus
              error={newFolderError}
              helperText={newFolderError}
              margin="dense"
              label="Folder Name"
              fullWidth
              variant="standard"
              value={newFolderName}
              onChange={(e) => setNewFodlerName(e.target.value)}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateFolderFormDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleCreateFolderSubmit}>Create</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editFolderFormDialogOpen}
        onClose={() => setEditFolderFormDialogOpen(false)}
      >
        <DialogTitle>Rename Folder</DialogTitle>
        <DialogContent>
          <DialogContentText>Input new folder name</DialogContentText>
          <form onSubmit={handleCreateFolderSubmit}>
            <TextField
              autoFocus
              error={newFolderError}
              helperText={newFolderError}
              margin="dense"
              label="Folder Name"
              fullWidth
              variant="standard"
              value={newFolderName}
              onChange={(e) => setNewFodlerName(e.target.value)}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditFolderFormDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleEditFolderNameSave}>SAVE</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ShipmentCategoryList;
