import * as React from "react";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const FloatingAddButton = (props) => {
  const { handleShipmentDialogFormOpen } = props;

  const handleAddFabClick = () => {
    handleShipmentDialogFormOpen();
  };

  return (
    <Fab
      color="primary"
      aria-label="add"
      style={{
        margin: 0,
        top: "auto",
        right: 20,
        bottom: 15,
        left: "auto",
        position: "fixed",
      }}
      onClick={handleAddFabClick}
    >
      <AddIcon />
    </Fab>
  );
};

export default FloatingAddButton;
