import {
  Grid,
  Typography,
  Box,
  CircularProgress,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import { useEffect, useState } from "react";
import ShipmentItemCard from "./ShipmentItemCard";
import ShipmentStatusChip from "./ShipmentStatusChip";
import InfiniteScroll from "react-infinite-scroller";
import TrackingStatusDialog from "./TrackingStatusDialog";

const getStatusCounts = (shipments) => {
  let latestStatuses = shipments.map((item) =>
    item.ShipmentStatuses && item.ShipmentStatuses.length > 0
      ? {
          status:
            item.ShipmentStatuses[item.ShipmentStatuses.length - 1].status,
          type: item.ShipmentStatuses[item.ShipmentStatuses.length - 1].type,
          shipment_id: item.id,
        }
      : {}
  );

  latestStatuses.sort((a, b) => {
    if (a.type === "success") return 1;
    else if (b.type === "success") return -1;
    else if (a.type === "warning") return -1;
    else if (b.type === "warning") return 1;
    else return 0;
  });

  let status_count = [];

  for (let i = 0; i < latestStatuses.length; i++) {
    if (
      latestStatuses[i].status &&
      latestStatuses[i].status !== null &&
      latestStatuses[i].status !== ""
    ) {
      if (latestStatuses[i].status in status_count) {
        status_count[latestStatuses[i].status].count += 1;
        status_count[latestStatuses[i].status].shipment_ids.push(
          latestStatuses[i].shipment_id
        );
      } else {
        status_count[latestStatuses[i].status] = {
          count: 1,
          type: latestStatuses[i].type,
          shipment_ids: [latestStatuses[i].shipment_id],
        };
      }
    }
  }

  return Object.entries(status_count).map(([k, v]) => ({
    status: k,
    value: v,
  }));
};

const ProgressCircle = (props) => {
  return (
    <Box
      sx={{
        mt: 5,
        display: "flex",
        flexGrow: 1,
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

const ShipmentList = (props) => {
  const { shipments, loading, onActiveFolderChanged, isAdmin } = props;
  const [filteredShipments, setFIlteredShipments] = useState([]);
  const [loadedShipments, setLoadedShipments] = useState([]);
  const [statusCounts, setStatusCounts] = useState([]);
  const [totalShipmentCounts, setTotalShipmentCounts] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState({});
  const [trackingDialogOpen, setTrackingDialogOpen] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState({});
  const [selectedShipmentId, setSelectedShipmentId] = useState(null);

  const handleStatusChipClick = (stat) => {
    if (selectedStatus.status === stat.status) {
      setSelectedStatus({});
    } else {
      setSelectedStatus(stat);
    }
  };

  const LoadMoreShipments = () => {
    const loadedShipmentCount = loadedShipments.length;
    const filteredShipmentCount = filteredShipments.count;
    const defaultLoadCount = 2;
    const howManyToLoad =
      loadedShipmentCount + defaultLoadCount > filteredShipmentCount
        ? filteredShipmentCount - loadedShipmentCount
        : defaultLoadCount;

    setLoadedShipments((prevState) =>
      filteredShipments.slice(0, prevState.length + howManyToLoad)
    );
  };

  const handleShipmentStatusClick = (shipment, latestStatus) => {
    setSelectedShipment({ ...shipment, latestStatus: latestStatus });
    setSelectedShipmentId(shipment.id);
    setTrackingDialogOpen(true);
  };

  const handleShipmentStatusReload = (id) => {};

  const getLatestStatus = (shipment) => {
    return shipment.ShipmentStatuses && shipment.ShipmentStatuses.length > 0
      ? shipment.ShipmentStatuses[shipment.ShipmentStatuses.length - 1]
      : {};
  };

  useEffect(() => {
    const sc = getStatusCounts(shipments);
    setStatusCounts(sc);
    setTotalShipmentCounts(shipments.length);
    setSelectedStatus("");
  }, [shipments]);

  useEffect(() => {
    if (selectedShipmentId) {
      const updatedShipment = shipments.find(
        (shipment) => shipment.id === selectedShipmentId
      );
      if (updatedShipment) {
        const latestStatus = getLatestStatus(updatedShipment);
        setSelectedShipment({ ...updatedShipment, latestStatus: latestStatus });
      }
    }
  }, [shipments, selectedShipmentId]);

  useEffect(() => {
    let shipmentsWithLatestStatus = shipments.map((shipment) => {
      const latestStatus = getLatestStatus(shipment);

      return { ...shipment, latestStatus: latestStatus };
    });

    shipmentsWithLatestStatus.sort((a, b) => {
      if (a.latestStatus.type === "success") return 1;
      else if (b.latestStatus.type === "success") return -1;
      else if (a.latestStatus.type === "warning") return -1;
      else if (b.latestStatus.type === "warning") return 1;
      else return 0;
    });

    if (!selectedStatus.status) {
      setFIlteredShipments(shipmentsWithLatestStatus);
    } else {
      setFIlteredShipments(
        shipmentsWithLatestStatus.filter((item) =>
          selectedStatus.value.shipment_ids.some(
            (shipment_id) => shipment_id === item.id
          )
        )
      );
    }
  }, [selectedStatus, shipments]);

  useEffect(() => {
    setLoadedShipments((prevState) =>
      filteredShipments.slice(0, prevState.length)
    );
  }, [filteredShipments]);

  return shipments && shipments.length > 0 ? (
    <Grid container spacing={1} sx={{ mt: 1, mb: 5 }}>
      <Grid item xs={12}>
        <Typography variant="h6">Shipments</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Table aria-label="tracking-table" size="small" sx={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell align="center">Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statusCounts &&
              statusCounts.map((row, i) => {
                return (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <ShipmentStatusChip
                        status={row.status}
                        color={row.value.type}
                        variant={
                          selectedStatus.status === row.status
                            ? "filled"
                            : "outlined"
                        }
                        size="medium"
                        onClick={() => handleStatusChipClick(row)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Typography fontWeight="bold">
                        {row.value.count}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell component="th" scope="row">
                <Typography>Total</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight="bold">{totalShipmentCounts}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <br />
      <Grid item xs={12}>
        <InfiniteScroll
          loadMore={LoadMoreShipments}
          hasMore={filteredShipments.length > loadedShipments.length}
          loader={<ProgressCircle key="loader" />}
        >
          <Grid container spacing={1}>
            {loadedShipments.map((item, i) => {
              return (
                <Grid item key={i} xs={12}>
                  <ShipmentItemCard
                    item={item}
                    onActiveFolderChanged={onActiveFolderChanged}
                    onShipmentStatusClick={handleShipmentStatusClick}
                    isAdmin={isAdmin}
                  />
                </Grid>
              );
            })}
          </Grid>
        </InfiniteScroll>
      </Grid>
      {selectedShipment && (
        <TrackingStatusDialog
          shipment={selectedShipment}
          open={trackingDialogOpen}
          onClose={() => setTrackingDialogOpen(false)}
          isAdmin={isAdmin}
        />
      )}
    </Grid>
  ) : (
    loading && <ProgressCircle />
  );
};

export default ShipmentList;
