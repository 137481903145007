import { useState } from "react";
import { Container } from "@mui/system";
import { useDispatch } from "react-redux";
import { addShipmentImage } from "../features/shipments/shipmentSlice";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";

function ShipmentImageFormDialog(props) {
  const { shipment, open, onClose } = props;

  const initialState = {
    name: "",
    link: "",
  };

  const [newImageData, setNewImageData] = useState(initialState);
  const dispatch = useDispatch();

  const handleFormChange = (e) => {
    setNewImageData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(addShipmentImage({ ...newImageData, shipment_id: shipment.id }));
    setNewImageData(initialState);
    onClose();
  };

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle sx={{ p: 2 }}>Add Image / Photo</DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Container>
          <TextField
            label="Name"
            name="name"
            fullWidth
            multiline
            maxRows={3}
            size="small"
            sx={{ mt: 1 }}
            value={newImageData.name}
            onChange={handleFormChange}
          />
          <TextField
            label="Link"
            name="link"
            fullWidth
            size="small"
            sx={{ mt: 1 }}
            value={newImageData.link}
            onChange={handleFormChange}
          />
        </Container>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button color="secondary" onClick={onClose} tabIndex={-1}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Add</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShipmentImageFormDialog;
