import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Link,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getNotifications,
  readNotification,
  readAllNotifications,
} from "../features/notifications/notificationSlice";
import { WhatsApp } from "@mui/icons-material";

const dateOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  timezone: "Asia/Jakarta",
};

const NotificationList = (props) => {
  const { isAdmin } = props;
  const { notifications } = useSelector((state) => state.notifications);
  const unreadNotification = (notification) => notification.read === false;
  const phoneRgx = /(?:[+() ]*\d){10,13}/gm;

  const dispatch = useDispatch();

  const refreshNotification = () => {
    dispatch(getNotifications());
  };

  const handleMarkAllNotificationsClick = (e) => {
    dispatch(readAllNotifications({}));
  };

  const handleNotificationCheck = (notification) => {
    if (!notification.read) dispatch(readNotification(notification));
  };

  useEffect(() => {
    refreshNotification();
  }, []);

  return (
    <Box sx={{ width: 300 }}>
      <Stack direction="row" alignItems="center" sx={{ mt: 3 }}>
        <Typography variant="h5" sx={{ ml: 2, flexGrow: 1 }}>
          Notification
        </Typography>
        <IconButton sx={{ mr: 1 }} onClick={refreshNotification}>
          <RefreshIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="caption" sx={{ ml: 2 }}>
          {notifications
            ? notifications.filter((n) => n.read !== true).length
            : 0}
          {" unread notifications"}
        </Typography>
        <Tooltip title="Mark all as read">
          <span>
            <Button
              size="small"
              variant="outlined"
              color="inherit"
              sx={{ mr: 2 }}
              disabled={!notifications.some(unreadNotification)}
              onClick={handleMarkAllNotificationsClick}
            >
              MARK ALL
            </Button>
          </span>
        </Tooltip>
      </Stack>
      <List>
        {notifications &&
          notifications.map((notification) => {
            return (
              <ListItem
                key={notification.id}
                secondaryAction={
                  isAdmin && (
                    <Tooltip
                      title={
                        notification.read
                          ? `Read by ${notification.readBy}`
                          : "Mark as Read"
                      }
                    >
                      <Checkbox
                        edge="end"
                        checked={notification.read}
                        onChange={() => handleNotificationCheck(notification)}
                      />
                    </Tooltip>
                  )
                }
              >
                <Stack>
                  <Typography
                    variant="body2"
                    className="display-linebreak"
                    sx={{ mt: 0.3, mr: 1 }}
                  >
                    <Link
                      underline="hover"
                      href={"/shipment/" + notification.Shipment.id}
                      target="blank"
                    >
                      {notification.Shipment.shipmentNo}
                    </Link>
                  </Typography>
                  <Typography variant="caption">
                    {notification.Shipment.to.split(/\r?\n/)[0]}
                  </Typography>
                  <Typography variant="body1" fontWeight="semibold">
                    {notification.username} {notification.title}
                  </Typography>
                  {notification.description &&
                    notification.description.split("-").map((part, i) => {
                      const str = part;
                      const matches = str?.match(phoneRgx);
                      const phones = matches?.map(function (s) {
                        if (s.trim().startsWith("0")) {
                          return `+62${s.trim().substring(1)}`;
                        } else {
                          return s.trim();
                        }
                      });
                      if (matches && matches.length > 0) {
                        return (
                          <Box key={i}>
                            {part}
                            <Link
                              href={`https://wa.me/${phones[0]}`}
                              target="blank"
                            >
                              <WhatsApp sx={{ mb: -0.5 }} />
                            </Link>
                          </Box>
                        );
                      } else {
                        return (
                          <Typography key={i} variant="body2">
                            {part}
                          </Typography>
                        );
                      }
                    })}

                  <Typography variant="caption">
                    {new Date(notification.createdAt).toLocaleTimeString(
                      "id-ID",
                      dateOptions
                    )}
                  </Typography>
                </Stack>
              </ListItem>
            );
          })}
      </List>
    </Box>
  );
};

export default NotificationList;
