import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import CloudIcon from "@mui/icons-material/Cloud";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout, reset } from "../features/auth/authSlice";
import { DarkMode, LightMode, NotificationsRounded } from "@mui/icons-material";
import { Badge, Drawer, Link, Stack } from "@mui/material";
import NotificationList from "./NotificationList";
import { getNotifications } from "../features/notifications/notificationSlice";
import { useEffect } from "react";
import AccountDialog from "./AccountDialog";

const pages = [
  { label: "Dashboard", href: "/" },
  { label: "Users", href: "/users", adminOnly: true },
];

const Navbar = (props) => {
  const { isAdmin } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { notifications } = useSelector((state) => state.notifications);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [accountDialogOpen, setAccountDialogOpen] = React.useState(false);
  const [notificationDrawerOpen, setNotificationDrawerOpen] =
    React.useState(false);
  const [isDarkMode, setIsDarkMode] = React.useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handlePageClick = (href) => {
    navigate(href);
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleAccountMenuClick = () => {
    setAccountDialogOpen(true);
    setAnchorElUser(null);
  };

  const handleAccountDialogClose = () => {
    setAccountDialogOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/");
  };

  const handleDarkModeChange = () => {
    localStorage.setItem("darkMode", !isDarkMode);
    setIsDarkMode(!isDarkMode);
    navigate(0);
  };

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  useEffect(() => {
    const darkMode = localStorage.getItem("darkMode");
    if (darkMode) {
      setIsDarkMode(darkMode === "true");
    }
  }, []);

  return (
    <AppBar position="static" color="transparent">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page, i) => (
                <MenuItem
                  key={i}
                  onClick={() => handlePageClick(page.href)}
                  disabled={!isAdmin && page.adminOnly}
                >
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              mr: 2,
              flexGrow: 1,
              display: { xs: "flex" },
              height: 25,
            }}
          >
            <a href="/">
              <img src="/skylog-logo.png" style={{ height: 25 }} />
            </a>
            {pages.map((page, i) =>
              !isAdmin && page.adminOnly ? null : (
                <Link
                  key={i}
                  href={page.href}
                  underline="none"
                  color="inherit"
                  sx={{ ml: 3, display: { xs: "none", md: "flex" } }}
                >
                  {page.label}
                </Link>
              )
            )}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              sx={{ mr: 2 }}
              onClick={() => setNotificationDrawerOpen(true)}
            >
              <Badge
                badgeContent={notifications ? notifications.length : 0}
                color="error"
              >
                <NotificationsRounded />
              </Badge>
            </IconButton>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Stack direction="row" alignItems="center">
                {user && (
                  <Box sx={{ mx: 2, mt: 1 }}>
                    <Typography variant="h6">{user.username}</Typography>
                    <Typography variant="subtitle2">{user.email}</Typography>
                  </Box>
                )}
                <IconButton sx={{ mr: 1 }} onClick={handleDarkModeChange}>
                  {!isDarkMode ? <DarkMode /> : <LightMode />}
                </IconButton>
              </Stack>
              <Box sx={{ mx: 2 }}>
                <hr />
              </Box>

              <MenuItem onClick={handleAccountMenuClick}>
                <Typography>Account</Typography>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Typography>Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <Drawer
        anchor="right"
        open={notificationDrawerOpen}
        onClose={() => setNotificationDrawerOpen(false)}
      >
        <NotificationList isAdmin={isAdmin} />
      </Drawer>
      <AccountDialog
        open={accountDialogOpen}
        handleClose={handleAccountDialogClose}
      />
    </AppBar>
  );
};
export default Navbar;
