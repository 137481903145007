import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import "./App.css";
import Shipment from "./pages/Shipment";
import ResetPassword from "./pages/ResetPassword";
import Users from "./pages/Users";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { useEffect, useState } from "react";

const theme = (darkMode) =>
  createTheme({
    typography: {
      fontFamily: [
        "Inter",
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    palette: {
      mode: darkMode ? "dark" : "light",
      ...(!darkMode
        ? {
            // palette values for light mode
            primary: {
              light: "#6d72bf",
              main: "#3949ab",
              dark: "#020570",
              contrastText: "#fff",
            },
            secondary: {
              light: "#ffebee",
              main: "#ffcdd2",
              dark: "#ef9a9a",
              contrastText: "#000",
            },
            info: {
              light: "#6d72bf",
              main: "#3949ab",
              dark: "#020570",
              contrastText: "#fff",
            },

            background: {
              default: "#eeeeee",
              paper: "#f5f5f5",
            },
            divider: "#CCCCCC",
            text: {
              primary: "#0A0A0A",
              secondary: "#222222",
            },
          }
        : {
            // palette values for dark mode
            primary: {
              light: "#7986cb",
              main: "#3f51b5",
              dark: "#3949ab",
              contrastText: "#fff",
            },
            secondary: {
              light: "#ffebee",
              main: "#ffcdd2",
              dark: "#ef9a9a",
              contrastText: "#000",
            },
            info: {
              light: "#7986cb",
              main: "#3f51b5",
              dark: "#3949ab",
              contrastText: "#fff",
            },
            error: {
              light: "#e57373",
              main: "#ef5350",
              dark: "#f44336",
              contrastText: "#000",
            },
            divider: "#f0f0f0",
            background: {
              default: "#121219",
              paper: "#141320",
            },
            text: {
              primary: "#FFFFFF",
              secondary: "#FFFFFF",
            },
          }),
    },
  });

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const darkMode = localStorage.getItem("darkMode");
    if (darkMode) {
      setIsDarkMode(darkMode === "true");
    }
  }, []);

  return (
    <ThemeProvider theme={theme(isDarkMode)}>
      <CssBaseline />
      <Router>
        <div className="container">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/Shipment/:id" element={<Shipment />} />
            <Route path="/login" element={<Login />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/users" element={<Users />} />
          </Routes>
        </div>
      </Router>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
