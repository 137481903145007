import React from "react";
import TrackingListItem from "./TrackingListItem";
import { List } from "@mui/material";

const TrackingList = (props) => {
  const { items, onDelete } = props;
  return (
    <List dense={true} sx={{ mt: 2, maxHeight: 300, overflow: "auto" }}>
      {items &&
        items.map((item, key) => (
          <TrackingListItem
            key={key}
            index={key}
            item={item}
            onDelete={onDelete}
          />
        ))}
    </List>
  );
};

export default TrackingList;
