import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { useSelector, useDispatch } from "react-redux";
import ScrollTop from "../components/ScrollTop";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteShipment,
  reset,
  getShipment,
  getShipmentFolders,
  updateShipmentIsClosed,
  getShipmentPublic,
} from "../features/shipments/shipmentSlice";
import queryString from "query-string";
import ShipmentItemCard from "../components/ShipmentItemCard";
import TrackingStatusTimeline from "../components/TrackingStatusTimeline";
import ShipmentStatusChip from "../components/ShipmentStatusChip";
import TrackingStatusFormDialog from "../components/TrackingStatusFormDialog";
import TrackingStatusDialog from "../components/TrackingStatusDialog";
import { Delete } from "@mui/icons-material";
import ShipmentLogsList from "../components/ShipmentLogsList";
import { logout } from "../features/auth/authSlice";

const Shipment = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [latestStatus, setLatestStatus] = useState(null);
  const [updateStatusFormDialogOpen, setUpdateStatusFormDialogOpen] =
    useState(false);
  const [
    updateStatusPublicFormDialogOpen,
    setUpdateStatusPublicFormDialogOpen,
  ] = useState(false);
  const [trackingDialogOpen, setTrackingDialogOpen] = useState(false);
  const [deleteShipmentDialogOpen, setDeleteShipmentDialogOpen] =
    useState(false);
  const [shipmentStatusDraftsExpanded, setShipmentStatusDraftsExpanded] =
    useState(false);

  const { user } = useSelector((state) => state.auth);
  const { shipment, isError, message, isLoading } = useSelector(
    (state) => state.shipments
  );

  const isEditable = isAdmin && !shipment.isClosed;

  const handleCloseShipment = (e) => {
    e.preventDefault();
    dispatch(updateShipmentIsClosed({ ...shipment, isClosed: true }));
  };

  const handleReopenShipment = (e) => {
    e.preventDefault();
    dispatch(updateShipmentIsClosed({ ...shipment, isClosed: false }));
  };

  const handleDeleteShipmentClick = () => {
    setDeleteShipmentDialogOpen(true);
  };

  const handleConfirmDeleteShipemnt = async () => {
    onActiveFolderChanged(shipment.folder);
    await dispatch(deleteShipment(id));
  };

  let location = useLocation();

  useEffect(() => {
    // if (!user) {
    //   const queryParams = { returnUrl: location.pathname };
    //   const qString = queryString.stringify(queryParams);
    //   navigate(`/login?${qString}`);
    // }
  }, [user, navigate]);

  useEffect(() => {
    if (isError && message === "Unauthorized") {
      dispatch(reset());
      dispatch(logout());
    }
  }, [isError, message, dispatch]);

  useEffect(() => {
    if (user) {
      setIsAdmin(user.role === "ADMIN" || user.role === "SUPERADMIN");
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (user) dispatch(getShipment(id));
    else dispatch(getShipmentPublic(id));
  }, [user, id, dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(getShipmentFolders());
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (shipment != null) {
      if (shipment.ShipmentStatuses && shipment.ShipmentStatuses.length > 0) {
        setLatestStatus({
          status:
            shipment.ShipmentStatuses[shipment.ShipmentStatuses.length - 1]
              .status,
          type: shipment.ShipmentStatuses[shipment.ShipmentStatuses.length - 1]
            .type,
        });
      }
    }
  }, [shipment]);

  const onActiveFolderChanged = (newActiveFolder) => {
    const queryParams = { activeFolder: newActiveFolder };
    const qString = queryString.stringify(queryParams);
    navigate(`/?${qString}`);
  };

  const handleUpdateStatusFormDialogOpen = () => {
    setUpdateStatusFormDialogOpen(true);
  };

  const handleUpdateStatusFormDialogClose = () => {
    setUpdateStatusFormDialogOpen(false);
  };

  const handleShipmentStatusReload = () => {
    setUpdateStatusFormDialogOpen(false);
  };

  const handleSignInClick = () => {
    const queryParams = { returnUrl: location.pathname };
    const qString = queryString.stringify(queryParams);
    navigate(`/login?${qString}`);
  };

  return user ? (
    <>
      <CssBaseline />
      <div
        style={{
          minHeight: "100vh",
          marginBottom: "25px",
        }}
      >
        <CssBaseline />
        <Navbar isAdmin={isAdmin} />
        {isLoading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <Container maxWidth="xl">
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs={12}>
              {shipment && latestStatus && (
                <ShipmentStatusChip
                  status={latestStatus.status}
                  variant="filled"
                  color={latestStatus.type}
                  size="large"
                  sx={{ m: 2 }}
                />
              )}
              {isEditable && (
                <Button size="small" onClick={handleUpdateStatusFormDialogOpen}>
                  UPDATE
                </Button>
              )}
              <Button
                size="small"
                onClick={setTrackingDialogOpen}
                color="inherit"
              >
                Shipment Status
              </Button>
              <ShipmentLogsList shipment={shipment} />

              {isEditable && (
                <TrackingStatusFormDialog
                  shipment={shipment}
                  open={updateStatusFormDialogOpen}
                  onClose={handleUpdateStatusFormDialogClose}
                  onUpdate={handleShipmentStatusReload}
                />
              )}
              {shipment && shipment.ShipmentStatuses && (
                <Box sx={{ maxWidth: 550 }}>
                  <TrackingStatusTimeline
                    statuses={[
                      shipment.ShipmentStatuses[
                        shipment.ShipmentStatuses.length - 1
                      ],
                    ]}
                    shipment={shipment}
                    isEditable={isEditable}
                  />
                  {isAdmin &&
                    shipment.ShipmentStatusDrafts &&
                    shipment.ShipmentStatusDrafts.length > 0 && (
                      <Box>
                        <Button
                          onClick={() =>
                            setShipmentStatusDraftsExpanded(
                              (prevState) => !prevState
                            )
                          }
                        >
                          +{shipment.ShipmentStatusDrafts.length} PENDING
                          UPDATES
                        </Button>
                        <Collapse
                          in={shipmentStatusDraftsExpanded}
                          timeout="auto"
                          unmountOnExit
                        >
                          <TrackingStatusTimeline
                            statuses={shipment.ShipmentStatusDrafts}
                            shipment={shipment}
                            draft
                          />
                        </Collapse>
                      </Box>
                    )}
                  <TrackingStatusDialog
                    shipment={shipment}
                    open={trackingDialogOpen}
                    onClose={() => setTrackingDialogOpen(false)}
                    isAdmin={isAdmin}
                  />
                </Box>
              )}
            </Grid>
            <Grid item lg={12} xs={12}>
              <div id="back-to-top-anchor"></div>
              {shipment && (
                <ShipmentItemCard
                  item={shipment}
                  onActiveFolderChanged={onActiveFolderChanged}
                  expand={true}
                  showMore={true}
                  isAdmin={isAdmin}
                />
              )}
              {isError && message}
            </Grid>
          </Grid>
          {isAdmin && (
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              justify="center"
            >
              {shipment && !shipment.isClosed ? (
                <Grid item>
                  <Typography variant="body2" sx={{ mt: 5, mb: 1 }}>
                    Is this shipment completed?
                  </Typography>
                  <Button
                    fullWidth
                    variant="text"
                    color="success"
                    sx={{ mb: 4 }}
                    onClick={handleCloseShipment}
                    disabled={isLoading}
                  >
                    Close Shipment
                  </Button>
                </Grid>
              ) : (
                <Grid item>
                  <Typography variant="body2" sx={{ mt: 5, mb: 1 }}>
                    This shipment is CLOSED
                  </Typography>
                  <Button
                    fullWidth
                    variant="text"
                    color="info"
                    sx={{ mb: 4 }}
                    onClick={handleReopenShipment}
                    disabled={isLoading}
                  >
                    Reopen
                  </Button>
                </Grid>
              )}
              <Grid item xs={12} justifyContent="center">
                <Typography
                  variant="body2"
                  align="center"
                  sx={{ mt: 1, mb: 1 }}
                >
                  DANGER!
                </Typography>
                <Button
                  fullWidth
                  variant="text"
                  color="error"
                  sx={{ mb: 1 }}
                  onClick={handleDeleteShipmentClick}
                  disabled={isLoading}
                >
                  <Delete />
                  Delete Shipment
                  <Delete />
                </Button>
              </Grid>
            </Grid>
          )}
          <Dialog
            open={deleteShipmentDialogOpen}
            onClose={() => setDeleteShipmentDialogOpen(false)}
          >
            <DialogTitle>{"Delete Shipment"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {
                  "Are you sure you want to delete this shipment? Deleting the shipment data also deletes all related data (Packages, Items, Trackings, etc)"
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteShipmentDialogOpen(false)}>
                Cancel
              </Button>
              <Button onClick={handleConfirmDeleteShipemnt}>Yes, Delete</Button>
            </DialogActions>
          </Dialog>
        </Container>

        <ScrollTop>
          <Fab sx={{ mr: 2 }} aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </div>
    </>
  ) : (
    <Box sx={{ minHeight: "100vh" }}>
      <Stack alignItems="center" justifyContent="center">
        <img id="searchLogo" src="/skylog-logo.png" alt="SKYLOG v2" />
        <Button onClick={handleSignInClick}>Sign In</Button>

        <Container maxWidth="xs">
          <Card>
            <CardContent>
              <Stack gap={2}>
                {isError && <Typography color="error">{message}</Typography>}
                <TextField
                  label="No. Shipment"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={shipment.shipmentNo}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label="Pengirim"
                  size="small"
                  fullWidth
                  variant="outlined"
                  multiline
                  value={shipment.from}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label="Penerima"
                  size="small"
                  fullWidth
                  multiline
                  variant="outlined"
                  value={shipment.to}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                {shipment.id && (
                  <div>
                    {shipment.isClosed ? (
                      <Typography color="error">
                        This shipment is closed
                      </Typography>
                    ) : (
                      <Button
                        onClick={() =>
                          setUpdateStatusPublicFormDialogOpen(true)
                        }
                      >
                        Update Status
                      </Button>
                    )}
                  </div>
                )}
                <TrackingStatusTimeline
                  statuses={shipment.ShipmentStatusDrafts ?? []}
                  shipment={shipment}
                />

                <TrackingStatusFormDialog
                  shipment={shipment}
                  open={updateStatusPublicFormDialogOpen}
                  onClose={() => setUpdateStatusPublicFormDialogOpen(false)}
                  onUpdate={() => setUpdateStatusPublicFormDialogOpen(false)}
                  publicAccess={true}
                />
              </Stack>
            </CardContent>
          </Card>
        </Container>
      </Stack>
    </Box>
  );
};

export default Shipment;
