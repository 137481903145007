import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addShipmentPackage,
  bulkAddShipmentPackage,
  bulkDeleteShipmentPackage,
  deleteShipmentPackage,
  updateShipmentPackage,
} from "../features/shipments/shipmentSlice";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TextField,
  Stack,
  TableContainer,
  Typography,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Tooltip,
  LinearProgress,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PasteIcon from "@mui/icons-material/ContentPaste";
import { NumericFormat } from "react-number-format";
import PackageTableRow from "./PackageTableRow";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const initialFormDataState = {
  shipment_id: null,
  packageNo: "",
  width: "0",
  length: "0",
  height: "0",
  volume: "0",
  unitWeight: "0",
  totalWeight: "0",
  volumeWeight: "0",
  chargeable: "0",
  chargeableUnit: "kg",
  qty: "1",
  unit: "C",
  note: "",
};

var reA = /[^a-zA-Z]/g;
var reN = /[^0-9]/g;
function az(a, b) {
  var AInt = parseInt(a.packageNo, 10);
  var BInt = parseInt(b.packageNo, 10);

  if (isNaN(AInt) && isNaN(BInt)) {
    var aA = a.packageNo.replace(reA, "");
    var bA = b.packageNo.replace(reA, "");
    if (aA === bA) {
      var aN = parseInt(a.packageNo.replace(reN, ""), 10);
      var bN = parseInt(b.packageNo.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  } else if (isNaN(AInt)) {
    //A is not an Int
    return 1; //to make alphanumeric sort first return -1 here
  } else if (isNaN(BInt)) {
    //B is not an Int
    return -1; //to make alphanumeric sort first return 1 here
  } else {
    return AInt > BInt ? 1 : -1;
  }
}

function PackageTable(props) {
  const { shipment, isEditable } = props;
  const dispatch = useDispatch();
  const [newRowVisible, setNewRowVisible] = useState(false);
  const [editableRowId, setEditableRowId] = useState(null);
  const [deletePackageDialogOpen, setDeletePackageDialogOpen] = useState(false);
  const [deleteAllPackagesDialogOpen, setDeleteAllPackagesDialogOpen] =
    useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [formData, setFormData] = useState(initialFormDataState);
  const [pastedValues, setPastedValues] = useState([]);
  const [pasteDataDialogOpen, setPasteDataDialogOpen] = useState(false);
  const [collapseAllPackageRow, setCollapseAllPackageRow] = useState(false);
  const [openedPackageRow, setOpenedPackageRow] = useState([]); // open or collapse
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("md"));
  let sortedPackages = shipment.ShipmentPackages
    ? [...shipment.ShipmentPackages]
    : [];
  sortedPackages.sort(az);

  const { isSuccess, isError, message, isLoading } = useSelector(
    (state) => state.shipments
  );

  useEffect(() => {
    if (editableRowId) {
      setFormData(
        shipment.ShipmentPackages.filter((e) => e.id === editableRowId)[0]
      );
      setNewRowVisible(false);
    } else {
      setFormData(initialFormDataState);
    }
  }, [editableRowId, shipment.ShipmentPackages]);

  const handleCollapsedPackageRow = (open, index) => {
    if (open) {
      setOpenedPackageRow((prevState) => [...prevState, index]);
    } else {
      setOpenedPackageRow((prevState) => prevState.filter((i) => i !== index));
    }
  };

  const handleCollapseAllPackageRow = (collapse) => {
    if (collapse) {
      setOpenedPackageRow([]);
    } else {
      setOpenedPackageRow(sortedPackages.map((p, i) => i));
    }
    setCollapseAllPackageRow(!collapseAllPackageRow);
  };

  const handleNewRowClick = () => {
    setNewRowVisible(true);
    setEditableRowId(null);
  };

  const handleFormDataChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDeletePackage = (row) => {
    setSelectedPackage(row);
    setDeletePackageDialogOpen(true);
  };

  const handleDeleteAllPackagesClick = (row) => {
    setDeleteAllPackagesDialogOpen(true);
  };

  const handleSubmitPastedData = () => {
    dispatch(
      bulkAddShipmentPackage({ shipment_id: shipment.id, data: pastedValues })
    );

    window.location.reload(false);
  };

  const handleConfirmDeletePackage = () => {
    dispatch(deleteShipmentPackage(selectedPackage));
    setDeletePackageDialogOpen(false);
    setSelectedPackage(null);
  };

  const calculateVolume = (p, l, t, qty) => {
    try {
      const v = Math.round(((p * l * t * qty) / 1000000) * 100) / 100;

      // Volume weight
      // Udara : /6000 or /5000
      // Darat : /4000
      // Laut : /1000000
      let vwDivider = 4000;
      let transportType = shipment.line ? shipment.line.toUpperCase() : "";
      if (transportType.includes("AIR")) {
        if (transportType.includes("2")) {
          vwDivider = 5000;
        } else {
          vwDivider = 6000;
        }
      } else if (transportType.includes("SEA")) {
        vwDivider = 1000000;
      }
      const vw = Math.round(((p * l * t * qty) / vwDivider) * 100) / 100;

      setFormData((prevState) => ({
        ...prevState,
        volume: v,
        volumeWeight: vw,
      }));
    } catch (error) {}
  };

  const calculateTotalWeight = (unitWeight, qty) => {
    try {
      const tw = Math.round(unitWeight * qty * 100) / 100;
      setFormData((prevState) => ({
        ...prevState,
        totalWeight: tw,
      }));
    } catch (error) {}
  };

  const handleSubmitFormData = (e) => {
    e.preventDefault();

    if (formData.id) {
      dispatch(updateShipmentPackage(formData));
      setEditableRowId(null);
    } else {
      dispatch(
        addShipmentPackage({
          shipment_id: shipment.id,
          packageNo: formData.packageNo,
          width: formData.width,
          length: formData.length,
          height: formData.height,
          volume: formData.volume,
          unitWeight: formData.unitWeight,
          totalWeight: formData.totalWeight,
          volumeWeight: formData.volumeWeight,
          chargeable: formData.chargeable,
          chargeableUnit: formData.chargeableUnit,
          qty: formData.qty,
          unit: formData.unit,
          note: formData.note,
        })
      );
      // setNewRowVisible(false);
      setEditableRowId(null);
      e.target.blur();
    }
  };

  const handlePaste = (e) => {
    try {
      e.clipboardData.items[0].getAsString((text) => {
        text = text.replace(/,/g, ".");
        let pastedRows = text.split("\n");
        let newPackages = [];
        let newPackage = {};
        let newItem = {};
        let cells = [];
        let prevRefNo = "";

        let cbm = 0;
        let unitWeight = 0;
        let packageNo = 1;

        pastedRows.forEach((pastedRow) => {
          cells = pastedRow.split("\t");
          if (cells.length !== 18) {
            return;
          }

          if (cells[9] !== "") {
            unitWeight =
              Math.round(
                (Number(cells[13] ?? 0) / Number(cells[12] ?? 1)) * 100
              ) / 100;

            prevRefNo =
              cells[1] !== ""
                ? `${cells[1]}${
                    cells[1] !== "" && cells[2] !== "" ? " + " : ""
                  }${cells[2]}`
                : prevRefNo;
            newPackage = {
              packageNo: packageNo,
              shipment_id: shipment.id,
              qty: cells[12] !== "" ? cells[12] : "0",
              unit: "KOLI",
              length: cells[9],
              width: cells[10],
              height: cells[11],
              volume: cells[16],
              unitWeight: unitWeight.toString(),
              totalWeight: cells[13],
              volumeWeight: cells[14],
              chargeable: cells[15],
              chargeableUnit: "kg",
              note: cells[17].trim(),
              ShipmentItems: [
                {
                  shipment_id: shipment.id,
                  item_no: cells[5],
                  ref_no: prevRefNo,
                  name: cells[6],
                  qty: cells[7] !== "" ? cells[7] : "0",
                  unit: cells[8],
                  batch_no: "",
                  expired: "",
                },
              ],
            };
            newPackages.push(newPackage);
            packageNo++;
          } else {
            newItem = {
              shipment_id: shipment.id,
              item_no: cells[5],
              ref_no: prevRefNo,
              name: cells[6],
              qty: cells[7] !== "" ? cells[7] : "0",
              unit: cells[8],
              batch_no: "",
              expired: "",
            };

            newPackages[newPackages.length - 1].ShipmentItems.push(newItem);
          }
        });

        setPastedValues(newPackages);
      });
    } catch (error) {
      console.log("Error when paste data " + error.message);
    }
  };

  const handleDeleteAllPacakages = () => {
    if (sortedPackages.length > 0) {
      const shipmentPackageData = {
        shipment_id: shipment.id,
        data: sortedPackages.map((p) => p.id),
      };
      dispatch(bulkDeleteShipmentPackage(shipmentPackageData));

      window.location.reload(false);
    }
  };

  return (
    <div>
      {isLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 2 }}
      >
        <Stack direction="row">
          <Typography variant="h6">Packages</Typography>

          {isEditable && (
            <Stack direction="row">
              {!newRowVisible && (
                <IconButton
                  aria-label="Add Shipment Package"
                  onClick={() => handleNewRowClick()}
                >
                  <AddIcon />
                </IconButton>
              )}
              <Tooltip title="PASTE DATA">
                <IconButton
                  aria-label="Paste Shipment Package"
                  onClick={() => setPasteDataDialogOpen(true)}
                >
                  <PasteIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </Stack>
        {isEditable && smallscreen && (
          <IconButton
            aria-label="Delete All Shipment Package"
            onClick={handleDeleteAllPackagesClick}
            color="error"
            size="small"
            disabled={sortedPackages.length <= 0}
          >
            <DeleteIcon />
          </IconButton>
        )}
        {isEditable && !smallscreen && (
          <Button
            aria-label="Delete All Shipment Package"
            onClick={handleDeleteAllPackagesClick}
            endIcon={<DeleteIcon />}
            color="error"
            size="small"
            disabled={sortedPackages.length <= 0}
          >
            DELETE ALL
          </Button>
        )}
      </Stack>
      <TableContainer>
        <Table aria-label="tracking-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                {collapseAllPackageRow ? (
                  <IconButton onClick={() => handleCollapseAllPackageRow(true)}>
                    <KeyboardArrowUpIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => handleCollapseAllPackageRow(false)}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                )}
              </TableCell>
              <TableCell sx={{ minWidth: 50 }}>No.</TableCell>
              <TableCell sx={{ minWidth: 80 }}>Satuan</TableCell>
              <TableCell sx={{ minWidth: 80 }}>Qty</TableCell>
              <TableCell sx={{ minWidth: 60 }}>Panjang (cm)</TableCell>
              <TableCell sx={{ minWidth: 60 }}>Lebar (cm)</TableCell>
              <TableCell sx={{ minWidth: 60 }}>Tinggi (cm)</TableCell>
              <TableCell sx={{ minWidth: 60 }}>Volume (cbm)</TableCell>
              <TableCell sx={{ minWidth: 80 }}>Berat Satuan (kg)</TableCell>
              <TableCell sx={{ minWidth: 80 }}>Berat Total (kg)</TableCell>
              <TableCell sx={{ minWidth: 80 }}>Berat Volume (kg)</TableCell>
              <TableCell sx={{ minWidth: 150 }}>Yang Ditagih</TableCell>
              <TableCell sx={{ minWidth: 120 }}>Note</TableCell>
              {isEditable && (
                <TableCell align="right">
                  {!smallscreen && !newRowVisible && (
                    <IconButton
                      aria-label="Add Shipment Package"
                      onClick={() => handleNewRowClick()}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {shipment.ShipmentPackages &&
              sortedPackages.map((row, i) => (
                <React.Fragment key={i}>
                  <PackageTableRow
                    row={row}
                    i={i}
                    rowOpened={openedPackageRow.some((x) => x === i)}
                    shipment={shipment}
                    editableRowId={editableRowId}
                    handleCollapsedPackageRow={handleCollapsedPackageRow}
                    setEditableRowId={setEditableRowId}
                    formData={formData}
                    setFormData={setFormData}
                    handleFormDataChange={handleFormDataChange}
                    handleDeletePackage={handleDeletePackage}
                    handleSubmitFormData={handleSubmitFormData}
                    calculateVolume={calculateVolume}
                    calculateTotalWeight={calculateTotalWeight}
                    isEditable={isEditable}
                  />
                </React.Fragment>
              ))}
            {newRowVisible && (
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell />
                <TableCell sx={{ minWidth: 50 }}>
                  <TextField
                    size="small"
                    fullWidth
                    variant="standard"
                    name="packageNo"
                    autoFocus
                    value={formData.packageNo}
                    onChange={handleFormDataChange}
                  />
                </TableCell>
                <TableCell sx={{ minWidth: 80 }}>
                  <TextField
                    size="small"
                    fullWidth
                    variant="standard"
                    name="unit"
                    value={formData.unit}
                    onChange={handleFormDataChange}
                  />
                </TableCell>
                <TableCell sx={{ minWidth: 80 }}>
                  <NumericFormat
                    customInput={TextField}
                    size="small"
                    fullWidth
                    decimalSeparator=","
                    thousandSeparator="."
                    valueIsNumericString={true}
                    variant="standard"
                    name="qty"
                    value={formData.qty}
                    onValueChange={(values, sourceInfo) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        qty: values.value,
                      }));
                      calculateVolume(
                        formData.height,
                        formData.length,
                        formData.width,
                        values.value
                      );
                      calculateTotalWeight(formData.unitWeight, values.value);
                    }}
                  />
                </TableCell>

                <TableCell sx={{ minWidth: 60 }}>
                  <NumericFormat
                    customInput={TextField}
                    size="small"
                    fullWidth
                    decimalSeparator=","
                    thousandSeparator="."
                    valueIsNumericString={true}
                    variant="standard"
                    name="length"
                    value={formData.length}
                    onValueChange={(values, sourceInfo) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        length: values.value,
                      }));
                      calculateVolume(
                        formData.qty,
                        formData.width,
                        formData.height,
                        values.value
                      );
                    }}
                  />
                </TableCell>
                <TableCell sx={{ minWidth: 60 }}>
                  <NumericFormat
                    customInput={TextField}
                    size="small"
                    fullWidth
                    decimalSeparator=","
                    thousandSeparator="."
                    valueIsNumericString={true}
                    variant="standard"
                    name="width"
                    value={formData.width}
                    onValueChange={(values, sourceInfo) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        width: values.value,
                      }));
                      calculateVolume(
                        formData.qty,
                        formData.length,
                        formData.height,
                        values.value
                      );
                    }}
                  />
                </TableCell>
                <TableCell sx={{ minWidth: 60 }}>
                  <NumericFormat
                    customInput={TextField}
                    size="small"
                    fullWidth
                    decimalSeparator=","
                    thousandSeparator="."
                    valueIsNumericString={true}
                    variant="standard"
                    name="height"
                    value={formData.height}
                    onValueChange={(values, sourceInfo) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        height: values.value,
                      }));
                      calculateVolume(
                        formData.qty,
                        formData.length,
                        formData.width,
                        values.value
                      );
                    }}
                  />
                </TableCell>
                <TableCell sx={{ minWidth: 60 }}>
                  <NumericFormat
                    customInput={TextField}
                    size="small"
                    fullWidth
                    decimalSeparator=","
                    thousandSeparator="."
                    valueIsNumericString={true}
                    variant="standard"
                    name="volume"
                    value={formData.volume}
                    onValueChange={(values, sourceInfo) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        volume: values.value,
                      }));
                    }}
                  />
                </TableCell>
                <TableCell sx={{ minWidth: 80 }}>
                  <NumericFormat
                    customInput={TextField}
                    size="small"
                    fullWidth
                    decimalSeparator=","
                    thousandSeparator="."
                    valueIsNumericString={true}
                    variant="standard"
                    name="unitWeight"
                    value={formData.unitWeight}
                    onValueChange={(values, sourceInfo) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        unitWeight: values.value,
                      }));
                      calculateTotalWeight(formData.qty, values.value);
                    }}
                  />
                </TableCell>
                <TableCell sx={{ minWidth: 80 }}>
                  <NumericFormat
                    customInput={TextField}
                    size="small"
                    fullWidth
                    decimalSeparator=","
                    thousandSeparator="."
                    valueIsNumericString={true}
                    variant="standard"
                    name="totalWeight"
                    value={formData.totalWeight}
                    onValueChange={(values, sourceInfo) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        totalWeight: values.value,
                      }));
                    }}
                  />
                </TableCell>
                <TableCell sx={{ minWidth: 80 }}>
                  <NumericFormat
                    customInput={TextField}
                    size="small"
                    fullWidth
                    decimalSeparator=","
                    thousandSeparator="."
                    valueIsNumericString={true}
                    variant="standard"
                    name="volumeWeight"
                    value={formData.volumeWeight}
                    onValueChange={(values, sourceInfo) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        volumeWeight: values.value,
                      }));
                    }}
                  />
                </TableCell>

                <TableCell sx={{ minWidth: 150 }}>
                  <Stack direction="row" alignItems="baseline">
                    <NumericFormat
                      customInput={TextField}
                      size="small"
                      fullWidth
                      decimalSeparator=","
                      thousandSeparator="."
                      valueIsNumericString={true}
                      variant="standard"
                      name="chargeable"
                      value={formData.chargeable}
                      onValueChange={(values, sourceInfo) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          chargeable: values.value,
                        }));
                      }}
                    />
                    <Select
                      value={formData.chargeableUnit}
                      variant="standard"
                      name="chargeableUnit"
                      sx={{ ml: 1, minWidth: 60 }}
                      onChange={(e) =>
                        setFormData((prevState) => ({
                          ...prevState,
                          chargeableUnit: e.target.value,
                        }))
                      }
                    >
                      <MenuItem value="kg">kg</MenuItem>
                      <MenuItem value="cbm">cbm</MenuItem>
                    </Select>
                  </Stack>
                </TableCell>

                <TableCell sx={{ minWidth: 120 }}>
                  <TextField
                    size="small"
                    fullWidth
                    multiline
                    variant="standard"
                    name="note"
                    value={formData.note}
                    onChange={handleFormDataChange}
                  />
                </TableCell>
                <TableCell align="right">
                  <Stack direction="row" justifyContent="flex-end">
                    <IconButton
                      aria-label="submit"
                      onClick={handleSubmitFormData}
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton
                      aria-label="cancel"
                      onClick={() => setNewRowVisible(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={pasteDataDialogOpen}
        onClose={() => setPasteDataDialogOpen(false)}
      >
        <DialogTitle>{"Paste Package data"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Paste data from excel</DialogContentText>
          <TextField
            size="small"
            onPaste={handlePaste}
            variant="outlined"
            label="Paste here"
            fullWidth
            sx={{ my: 2 }}
          />
          <TextField
            value={JSON.stringify(pastedValues, undefined, 2)}
            multiline
            fullWidth
            rows={10}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPastedValues([])}>Reset</Button>
          <Button onClick={() => setPasteDataDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSubmitPastedData} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deletePackageDialogOpen}
        onClose={() => setDeletePackageDialogOpen(false)}
      >
        <DialogTitle>{"Delete Package data"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Hapus data packages? Semua data item di dalam package ini akan ikut
            terhapus juga.
            <br />{" "}
            <i>
              Are you sure you want to delete this package? Deleting the package
              data also deletes the Items data inside the package.
            </i>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            onClick={() => setDeletePackageDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button color="error" onClick={handleConfirmDeletePackage} autoFocus>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteAllPackagesDialogOpen}
        onClose={() => setDeleteAllPackagesDialogOpen(false)}
      >
        <DialogTitle>{"Delete All Packages data"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Hapus semua{" ("}
            {sortedPackages.length}
            {") "}
            data packages? Semua data item di shipment ini akan ikut terhapus
            juga.
            <br />{" "}
            <i>
              Are you sure you want to delete all packages data? Deleting the
              package data also deletes the Items data inside the package.
            </i>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            onClick={() => setDeleteAllPackagesDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button color="error" onClick={handleDeleteAllPacakages} autoFocus>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PackageTable;
