import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import authService from "../features/auth/authService";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://skytrans.co.id/">
        SKYTRANS LOGISTICS
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

function ResetPassword() {
  const [formData, setFormData] = useState({
    newPassword: "",
    newPassword2: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [resetPasswordKey, setResetPasswordKey] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const accessKey = searchParams.get("accessKey") ?? "";
    if (accessKey === "") {
      navigate("/");
    } else {
      setResetPasswordKey(accessKey);
    }
  }, []);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (formData.newPassword !== formData.newPassword2) {
      return;
    }

    try {
      const data = { newPassword: formData.newPassword };

      const response = await authService.resetPassword(data, resetPasswordKey);
      if (response.message && response.message === "password changed") {
        navigate("/");
      }
    } catch (error) {
      alert(error.message);
    }
    setIsLoading(false);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Grid
          container
          component="main"
          justifyContent="center"
          sx={{
            height: "100vh",
          }}
        >
          <Grid
            item
            xs={12}
            sm={8}
            md={4}
            component={Paper}
            elevation={6}
            square
            alignContent="center"
          >
            <Box
              sx={{
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <img id="searchLogo" src="/skylog-logo.png" alt="SKYLOG" />
              <Typography variant="body2" sx={{ mt: -5, mb: 4 }}>
                v2
              </Typography>
              <Typography component="h1" variant="h5">
                Reset Your Password
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={onSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword"
                  value={formData.newPassword}
                  placeholder="Enter New password"
                  onChange={onChange}
                  label="New Password"
                  type="password"
                  id="password"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword2"
                  value={formData.newPassword2}
                  placeholder="Re-type New password"
                  onChange={onChange}
                  label="Re-type new password"
                  type="password"
                  id="newPassword2"
                />
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  isLoading={isLoading}
                >
                  Reset
                </LoadingButton>
                <Link href="/">Back to login</Link>
                <Copyright sx={{ my: 5 }} />
                <img
                  id="skytransLogo"
                  src="/SKY2021.png"
                  alt="SKYTRANS LOGISTICS"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}

export default ResetPassword;
