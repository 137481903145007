import { useEffect, useState } from "react";
import { Container } from "@mui/system";
import { useTheme } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";
import {
  createShipmentStatusDraft,
  editShipmentStatus,
  updateShipmentStatus,
} from "../features/shipments/shipmentSlice";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import TimelineDot from "@mui/lab/TimelineDot/TimelineDot";
import {
  DateTimeField as MuiDateTimeField,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import shipmentService from "../features/shipments/shipmentService";

const statusOptionsFull = [
  {
    status: "ON PROGRESS",
    title: "Menunggu kelengkapan dokumen",
    type: "info",
    setDescription: true,
  },
  {
    status: "ON PROGRESS",
    title: "Dokumen sudah lengkap",
    type: "info",
    setDescription: true,
  },
  {
    status: "SHIPPING",
    title: "Barang keluar dari gudang",
    type: "info",
    setDescription: true,
  },
  {
    status: "SHIPPING",
    title: "Barang berangkat menuju tujuan",
    type: "info",
    setDescription: true,
  },
  {
    status: "SHIPPING",
    title: "Barang tiba di bandara keberangkatan",
    type: "info",
    setDescription: true,
    location: true,
  },
  {
    status: "SHIPPING",
    title: "Barang masih dalam perjalanan",
    type: "info",
    setDescription: true,
    location: true,
  },
  {
    status: "SHIPPING",
    title: "Barang tiba di bandara tujuan",
    type: "info",
    setDescription: true,
    location: true,
  },
  {
    status: "SHIPPING",
    title: "Barang tiba di lokasi agent dan akan dikirimkan ke tujuan penerima",
    type: "info",
    setDescription: true,
    location: true,
  },
  {
    status: "DELIVERED",
    title: "Barang tiba di tujuan",
    type: "success",
    setDescription: true,
    receiver: true,
    location: true,
  },
  {
    status: "DELIVERED",
    title: "Dokumen dalam perjalanan kembali ke Jakarta",
    type: "info",
    setDescription: true,
  },
  {
    status: "DELIVERED",
    title: "Dokumen sudah tiba di Jakarta",
    type: "info",
    setDescription: true,
  },
  {
    status: "",
    title: "Lainnya",
    type: "info",
    setStatus: true,
    setTitle: true,
    setDescription: true,
    receiver: false,
    location: false,
  },
];

const statusOptionsPublic = [
  {
    status: "SHIPPING",
    title: "Barang tiba di bandara keberangkatan",
    type: "info",
    location: true,
  },
  {
    status: "SHIPPING",
    title: "Barang masih dalam perjalanan",
    type: "info",
    location: true,
  },
  {
    status: "SHIPPING",
    title: "Barang tiba di bandara tujuan",
    type: "info",
    location: true,
  },
  {
    status: "SHIPPING",
    title: "Barang tiba di lokasi agent dan akan dikirimkan ke tujuan penerima",
    type: "info",
    location: true,
  },
  {
    status: "DELIVERED",
    title: "Barang tiba di tujuan",
    type: "success",
    receiver: true,
    location: true,
  },
];

const statusOptions = (publicAccess) => {
  return publicAccess ? statusOptionsPublic : statusOptionsFull;
};

const DateTimeField = (props) => {
  const { defaultValue, onChange } = props;
  const today = dayjs().set("hour", 23).endOf("hour");
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiDateTimeField
        size="small"
        sx={{ mt: 1 }}
        label="Date time"
        maxDateTime={today}
        defaultValue={dayjs(defaultValue)}
        format="DD/MM/YYYY HH:mm:ss "
        onChange={(newValue) => onChange("timestamps", newValue)}
      />
    </LocalizationProvider>
  );
};

function TrackingStatusFormDialog(props) {
  const {
    shipment,
    open,
    onClose,
    onUpdate,
    edit,
    editedStatus,
    publicAccess,
  } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const dispatch = useDispatch();
  const [latlng, setlatlng] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const initialState = {
    shipment_id: shipment ? shipment.id : null,
    status: "",
    type: "info",
    title: "",
    description: "",
    receiver: "",
    geolocation: "",
  };

  const [newFormData, setNewFormData] = useState(initialState);
  const [phone, setPhone] = useState("");
  const { isSuccess, isError, message, isLoading } = useSelector(
    (state) => state.shipments
  );

  const gpsOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  function gpsSuccess(pos) {
    const crd = pos.coords;
    setNewFormData((prevState) => ({
      ...prevState,
      geolocation: `${crd.latitude}, ${crd.longitude}`,
    }));
  }

  function gpsErrors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {
    if (open && publicAccess) {
      if (navigator.geolocation) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then(function (result) {
            console.log(result);
            if (result.state === "granted") {
              //If granted then you can directly call your function here
              navigator.geolocation.getCurrentPosition(
                gpsSuccess,
                gpsErrors,
                gpsOptions
              );
            } else if (result.state === "prompt") {
              //If prompt then the user will be asked to give permission
              navigator.geolocation.getCurrentPosition(
                gpsSuccess,
                gpsErrors,
                gpsOptions
              );
            } else if (result.state === "denied") {
              //If denied then you have to show instructions to enable location
              alert("Please allow location access to this page");
            }
          });
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    }
  }, [open, publicAccess]);

  useEffect(() => {
    if (edit && editedStatus) {
      setNewFormData(editedStatus);
      setSelectedIndex(statusOptions(publicAccess).length - 1);
    }
  }, [edit, editedStatus]);

  useEffect(() => {
    if (isSuccess && !isError && !isLoading) {
      onUpdate();
    }
  }, [isSuccess, isError, isLoading]);

  const handleSelectedStatusChanged = (i) => {
    setSelectedIndex(i);
  };

  const handleFormChange = (e) => {
    setNewFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDatetimeFormChange = (name, value) => {
    let newDate = new Date().toISOString();
    try {
      newDate = value.$d.toISOString();
    } catch (error) {}

    setNewFormData((prevState) => ({
      ...prevState,
      [name]: newDate,
    }));
  };

  const getStatusType = (status) => {
    switch (status) {
      case "ON PROGRESS":
      case "SHIPPING":
      default:
        return "info";
      case "DELIVERED":
        return "success";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const receiver =
      statusOptions(publicAccess)[selectedIndex].receiver &&
      newFormData.receiver &&
      newFormData.receiver !== null &&
      newFormData.receiver !== ""
        ? `\nPenerima: ${newFormData.receiver}`
        : "";

    let newStatusData = {
      shipment_id: shipment.id,
      timestamps: newFormData.timestamps ?? new Date().toISOString(),
      status: statusOptions(publicAccess)[selectedIndex].setStatus
        ? newFormData.status
        : statusOptions(publicAccess)[selectedIndex].status,
      type: statusOptions(publicAccess)[selectedIndex].setStatus
        ? getStatusType(newFormData.status)
        : statusOptions(publicAccess)[selectedIndex].type,
      title: statusOptions(publicAccess)[selectedIndex].setTitle
        ? newFormData.title
        : statusOptions(publicAccess)[selectedIndex].title,
      description: `${newFormData.description}-${receiver}${
        statusOptions(publicAccess)[selectedIndex].location
          ? `- LT ${new Date().toTimeString()}`
          : ""
      }`,
      geolocation: statusOptions(publicAccess)[selectedIndex].location
        ? newFormData.geolocation
        : null,
    };

    let payload = {};
    if (publicAccess) {
      newStatusData = { ...newStatusData, phone: `08${phone}` };
      dispatch(createShipmentStatusDraft(newStatusData));
    } else {
      if (edit) {
        newStatusData = { ...newStatusData, id: editedStatus.id };
        dispatch(editShipmentStatus(newStatusData));
      } else {
        dispatch(updateShipmentStatus(newStatusData));
      }
    }
    setNewFormData({ ...initialState, geolocation: newStatusData.geolocation });
  };

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Update Status Shipment
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 15,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Container>
          {isError && <Typography color="error">{message}</Typography>}
          {publicAccess && (
            <TextField
              fullWidth
              size="small"
              label="No. Hp"
              placeholder="Masukkan nomor hp anda"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">08</InputAdornment>
                ),
              }}
              sx={{ mt: 2, mb: 1 }}
            />
          )}
          <List
            component="nav"
            dense
            aria-label="status-options-list"
            sx={{
              mb: 2,
              maxHeight: fullScreen ? 400 : 300,
              position: "relative",
              overflow: "auto",
            }}
          >
            {statusOptions(publicAccess).map((item, i) => (
              <div key={i}>
                <ListItemButton
                  selected={selectedIndex === i}
                  onClick={() => handleSelectedStatusChanged(i)}
                >
                  <ListItemIcon>
                    <TimelineDot
                      color={selectedIndex === i ? item.type : "grey"}
                    >
                      <CheckIcon />
                    </TimelineDot>
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    sx={
                      selectedIndex !== i
                        ? {
                            color: (theme) => theme.palette.grey[600],
                          }
                        : {}
                    }
                  />
                </ListItemButton>
              </div>
            ))}
          </List>
          <div>
            {/* {statusOptions[selectedIndex].setType && (
              <FormControl sx={{ mt: 1 }}>
                <InputLabel>Type</InputLabel>
                <Select
                  value={newFormData.type}
                  label="Type"
                  name="type"
                  onChange={handleFormChange}
                  size="small"
                >
                  <MenuItem value="info">
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <TimelineDot color="info" />
                      <Typography>Info</Typography>
                    </Stack>
                  </MenuItem>
                  <MenuItem value="success">
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <TimelineDot color="success" />
                      <Typography>Success</Typography>
                    </Stack>
                  </MenuItem>
                  <MenuItem value="warning">
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <TimelineDot color="warning" />
                      <Typography>Warning</Typography>
                    </Stack>
                  </MenuItem>
                  <MenuItem value="error">
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <TimelineDot color="error" />
                      <Typography>Problem</Typography>
                    </Stack>
                  </MenuItem>
                </Select>
              </FormControl>
            )} */}
            {statusOptions(publicAccess)[selectedIndex].setStatus && (
              <FormControl size="small" sx={{ mt: 1, minWidth: 200 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={newFormData.status}
                  label="Status"
                  name="status"
                  onChange={handleFormChange}
                >
                  <MenuItem value="ON PROGRESS">ON PROGRESS</MenuItem>
                  <MenuItem value="SHIPPING">SHIPPING</MenuItem>
                  <MenuItem value="DELIVERED">DELIVERED</MenuItem>
                </Select>
              </FormControl>
            )}
            {statusOptions(publicAccess)[selectedIndex].setTitle && (
              <TextField
                label="Title"
                name="title"
                fullWidth
                multiline
                maxRows={3}
                size="small"
                sx={{ mt: 1 }}
                value={newFormData.title}
                onChange={handleFormChange}
              />
            )}
            {!publicAccess && (
              <DateTimeField
                defaultValue={
                  newFormData.timestamps ?? new Date().toISOString()
                }
                onChange={handleDatetimeFormChange}
              />
            )}
            {statusOptions(publicAccess)[selectedIndex].setDescription && (
              <TextField
                label="Note"
                name="description"
                fullWidth
                multiline
                maxRows={3}
                size="small"
                sx={{ mt: 1 }}
                value={newFormData.description}
                onChange={handleFormChange}
              />
            )}
            {statusOptions(publicAccess)[selectedIndex].receiver && (
              <TextField
                label="Penerima"
                placeholder="Nama - no telp"
                name="receiver"
                fullWidth
                multiline
                maxRows={3}
                size="small"
                sx={{ mt: 1 }}
                value={newFormData.receiver}
                onChange={handleFormChange}
              />
            )}
            {statusOptions(publicAccess)[selectedIndex].location && (
              <TextField
                label="Location"
                name="geolocation"
                fullWidth
                size="small"
                sx={{ mt: 1 }}
                value={newFormData.geolocation}
                onChange={handleFormChange}
                disabled={publicAccess}
              />
            )}
          </div>
        </Container>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button color="secondary" tabIndex={-1} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={handleSubmit}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TrackingStatusFormDialog;
