import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { getShipmentLogs } from "../features/shipments/shipmentSlice";
import { Box, Divider, Stack, Typography } from "@mui/material";

const dateOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  timezone: "Asia/Jakarta",
};

const ShipmentLogItem = (props) => {
  const { log } = props;

  const objLogBody = JSON.parse(log.body ?? "{}");

  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="body2">
        {log.username} - {log.title}
      </Typography>
      <Stack sx={{ ml: 2 }}>
        {!Array.isArray(objLogBody)
          ? Object.keys(objLogBody).map((key, index) => (
              <Typography variant="caption" key={index}>
                {key}: {objLogBody[key]}
              </Typography>
            ))
          : objLogBody.map((l, index) => (
              <Typography variant="caption">
                {l[0]}: {l[1]} {l.length >= 3 ? `-> ${l[2]}` : ""}
              </Typography>
            ))}
      </Stack>
      <Typography variant="caption">
        {new Date(log.createdAt).toLocaleTimeString("id-ID", dateOptions)}
      </Typography>
      <Divider sx={{ mt: 1 }} />
    </Box>
  );
};

const ShipmentLogsList = (props) => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { shipment, logs } = useSelector((state) => state.shipments);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (open && shipment) {
      dispatch(getShipmentLogs(shipment.id));
    }
  }, [open, shipment]);

  return (
    <React.Fragment>
      <Button onClick={() => setOpen(true)} color="inherit" size="small">
        LOGS
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          {shipment.shipmentNo} - Logs
        </DialogTitle>
        <DialogContent dividers>
          {logs &&
            logs.map((log) => <ShipmentLogItem key={log.id} log={log} />)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ShipmentLogsList;
